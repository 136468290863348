import * as types from '../actions/types'
import { FEEDBACK_WAITING } from '../lib/message-types'

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, {type, payload}) => {
  switch (type) {
    case types.LOAD_STOCKHISTORY_PART_STARTED:
      return Object.assign({}, state, {
        message: "Loading..."
        , messageSeverity:FEEDBACK_WAITING
        , lines:null
        , part:null
        , page: null
        , lineCount: null
        , totalLines: null
        , linesMessage: null
        , linesMessageSeverity:null
      });
    case types.LOAD_STOCKHISTORY_PART_FAILED:
      return Object.assign({}, state, {
        message: payload.message
        , messageSeverity:payload.severity
      });
      
    case types.LOAD_STOCKHISTORY_PART_COMPLETE:
      return Object.assign({}, state, {
        message: null
        , messageSeverity:null
      });
      
    case types.SET_STOCKHISTORY_PART:
      return Object.assign({}, state, {
        part: payload.part
        , lines: null
        , page: null
        , lineCount: null
        , totalLines: null
        , linesMessage: null
        , linesMessageSeverity:null
        , previousPart: null
        , modGRNEntryId: null
      });
      
    case types.TRY_STOCKHISTORY_MODJUMP_STARTED:
      return Object.assign({}, state, {
        message: "Finding mod..."
        , messageSeverity:FEEDBACK_WAITING
        , modGRNEntryId:payload.grnEntryId
      });
      
    case types.TRY_STOCKHISTORY_MODJUMP_FAILED:
      return Object.assign({}, state, {
        message: payload.message
        , messageSeverity:payload.severity
      });
      
    case types.TRY_STOCKHISTORY_MODJUMP_COMPLETE:
      return Object.assign({}, state, {
        message: null
        , messageSeverity:null
        , previousPart: state.part
        , part: payload.part
        , lines: null
        , page: null
        , lineCount: null
        , totalLines: null
        , modGRNEntryId: null
      });
      
    case types.LOAD_STOCKHISTORY_STARTED:
      return Object.assign({}, state, {
        linesMessage: "Loading lines..."
        , linesMessageSeverity:FEEDBACK_WAITING
      });
      
    case types.LOAD_STOCKHISTORY_FAILED:
      return Object.assign({}, state, {
        linesMessage: payload.message
        , linesMessageSeverity:payload.severity
      });
      
    case types.LOAD_STOCKHISTORY_COMPLETE:
      let lines = state.lines && payload.page != 1 ? [...state.lines ,...payload.lines] : payload.lines;
      return Object.assign({}, state, {
        linesMessage: null
        , linesMessageSeverity:null
        , lines: lines
        , page: payload.page
        , lineCount: lines.length
        , totalLines: payload.total
      });
      
    case types.CLEAR_STOCKHISTORY:
      return INITIAL_STATE;
      
    default:
      return state;
  }
}
