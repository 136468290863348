export const SID_COUNTRY = 1
export const SID_BRANCH = 2
export const SID_MANUFACTURER = 3
export const SID_CUSTOMER = 4
export const SID_SUPPLIER = 5
export const SID_PART = 6
export const SID_PAYTYPE = 7
export const SID_AIRCRAFTTYPES = 8
export const SID_LIFEUNITS = 9
export const SID_STOCKUNITS = 10
export const SID_HAZARDOUS = 11
export const SID_STORMTYPES = 12
export const SID_SHIPPINGMETHODS = 13
export const SID_GRN = 14
export const SID_TA = 15
export const SID_DA = 16
export const SID_CONDITIONS = 17
export const SID_CERTIFICATES = 18
export const SID_QUOTATION = 19
export const SID_BOOKINGGRNFROMTA = 20
export const SID_RO = 21
export const SID_EXCHANGECUSTOMER = 22
export const SID_EXCHANGESUPPLIER = 23
export const SID_BOOKINGROFROMGRN = 24
export const SID_BOOKINGDAFROMGRN = 25
export const SID_INVOICE = 26
export const SID_CREDITNOTE = 27
export const SID_ADHOCINVOICE = 28
export const SID_ADHOCDA = 29
export const SID_BOOKINGINVOICE = 30
export const SID_DEAL = 31
export const SID_OPERATOR = 32
export const SID_MATERIALCERT = 33
export const SID_MTA = 34
export const SID_BOOKINGMTA = 35
export const SID_BOOKINGUTA = 36
export const SID_UTA = 37
export const SID_REGNOTE = 38
export const SID_CUSTOMERGRADE = 39
export const SID_USER = 40
export const SID_PICTURE = 41
export const SID_COMMENT = 42
export const SID_TACOST = 43
export const SID_SUPPLEMENTARYCOST = 44
export const SID_REGNOTETYPES = 45
export const SID_UGRN = 46
export const SID_SKELETON = 48
export const SID_STOCKTAKE = 49
export const SID_TAREASON = 50
export const SID_JOBTITLE = 51
export const SID_CONTACT = 52
export const SID_PROFORMA = 53

export const isBaseTypeDA = type => {
  return type == SID_DA || type == SID_SUPPLEMENTARYCOST || type == SID_INVOICE || type == SID_CREDITNOTE || type == SID_ADHOCINVOICE || type == SID_ADHOCDA
}

export const isBaseTypeTA = type => {
  return type == SID_TA || type == SID_RO
}

export const STORMTYPES = {};

STORMTYPES[SID_COUNTRY] = "countries"
STORMTYPES[SID_BRANCH] = "branches"
STORMTYPES[SID_MANUFACTURER] = "manufacturers"
STORMTYPES[SID_CUSTOMER] = "customers"
STORMTYPES[SID_SUPPLIER] = "suppliers"
STORMTYPES[SID_PART] = "parts"
STORMTYPES[SID_PAYTYPE] = "paytypes"
STORMTYPES[SID_AIRCRAFTTYPES] = "aircrafttypes"
STORMTYPES[SID_LIFEUNITS] = "lifeunits"
STORMTYPES[SID_STOCKUNITS] = "stockunits"
STORMTYPES[SID_HAZARDOUS] = "hazards"
STORMTYPES[SID_STORMTYPES] = "stormtypes"
STORMTYPES[SID_SHIPPINGMETHODS] = "shippingmethods"
STORMTYPES[SID_GRN] = "grns"
STORMTYPES[SID_TA] = "tas"
STORMTYPES[SID_DA] = "das"
STORMTYPES[SID_CONDITIONS] = "conditions"
STORMTYPES[SID_CERTIFICATES] = "certificates"
STORMTYPES[SID_QUOTATION] = "quotations"
STORMTYPES[SID_BOOKINGGRNFROMTA] = "bookingsformtas"
STORMTYPES[SID_RO] = "tas"
STORMTYPES[SID_EXCHANGECUSTOMER] = "customerexchanges"
STORMTYPES[SID_EXCHANGESUPPLIER] = "supplierexchanges"
STORMTYPES[SID_BOOKINGROFROMGRN] = "bookingsrofromgrn"
STORMTYPES[SID_BOOKINGDAFROMGRN] = "bookingsdafromgrn"
STORMTYPES[SID_INVOICE] = "das"
STORMTYPES[SID_CREDITNOTE] = "das"
STORMTYPES[SID_ADHOCINVOICE] = "das"
STORMTYPES[SID_ADHOCDA] = "das"
STORMTYPES[SID_BOOKINGINVOICE] = "das"
STORMTYPES[SID_DEAL] = "deals"
STORMTYPES[SID_OPERATOR] = "operators"
STORMTYPES[SID_MATERIALCERT] = "materialcerts"
STORMTYPES[SID_MTA] = "mtas"
STORMTYPES[SID_BOOKINGMTA] = "mtabookings"
STORMTYPES[SID_BOOKINGUTA] = "utabookings"
STORMTYPES[SID_UTA] = "mtas"
STORMTYPES[SID_REGNOTE] = "regnotes"
STORMTYPES[SID_CUSTOMERGRADE] = "customergrades"
STORMTYPES[SID_USER] = "users"
STORMTYPES[SID_PICTURE] = "pictures"
STORMTYPES[SID_COMMENT] = "comments"
STORMTYPES[SID_TACOST] = "tacosts"
STORMTYPES[SID_SUPPLEMENTARYCOST] = "supplementarycosts"
STORMTYPES[SID_REGNOTETYPES] = "regnotetypes"
STORMTYPES[SID_UGRN] = "grns"
STORMTYPES[SID_SKELETON] = "skeletons"
STORMTYPES[SID_STOCKTAKE] = "stocktakes"
STORMTYPES[SID_TAREASON] = "tareasons"
STORMTYPES[SID_JOBTITLE] = "jobtitles"
STORMTYPES[SID_CONTACT] = "contacts"
STORMTYPES[SID_PROFORMA] = "das"

export const DEFAULTS = {}

DEFAULTS[SID_PAYTYPE] = 5
DEFAULTS[SID_TAREASON] = 1

export const DEAL_REPAIR = 9
export const DEAL_SALE = 1