import * as types from './constants'
import {FEEDBACK_WAITING, FEEDBACK_MESSAGE} from '../../lib/message-types'
import { TRANS_SALE } from '../../lib/transactions'

const initialState = {
  // searchMessage : null
  // , searchSeverity : null
  // , searchResults : null
  // , createMessage: null
  // , createMessageSeverity: null
  // , docMessage:null
  // , docMessageSeverity: null
  // , doc: null
  // , lines:{}
  // , linesMessage:null
  // , linesMessageSeverity:null
  // , linesLoaded:null
  // , line:null
  // , lineMessage:null
  // , lineMessageSeverity:null
  // , lookupSourceGRNLine:null
}

export default (state = initialState, {type, payload}) => {

  switch (type) {
    case types.START_NEW_GRN:
      return { doc: payload };
      
    case types.CREATE_GRN_FORM_SUBMITTED:
      return Object.assign({}, state, {
        docMessage:"Creating GRN..."
        , docMessageSeverity:FEEDBACK_WAITING
      });
      
    case types.CREATE_GRN_FAILED:
      return Object.assign({}, state, {
        docMessage:payload.message
        , docMessageSeverity:payload.severity
      });
     
    case types.CREATE_GRN_COMPLETE:
      return Object.assign({}, state, {
        docMessage:null
        , docMessageSeverity:null
      });
      
    case types.GRNS_SEARCH_STARTED:
      return Object.assign({}, state, {
        searchMessage:"Searching..."
        , searchMessageSeverity:FEEDBACK_WAITING
      });
      
    case types.GRNS_SEARCH_FAILED:
      return Object.assign({}, state, {
        searchMessage:payload.message
        , searchMessageSeverity:payload.severity
      });
      
    case types.GRNS_SEARCH_COMPLETE:
      let sm, sms;
      if(payload.results.length==0){
        sm = "No results, try with a wilcard '*' on the end";
        sms = FEEDBACK_MESSAGE;
      }
      return Object.assign({}, state, {
        searchMessage:sm
        , searchMessageSeverity:sms
        , searchResults:payload.results
      });

    // case types.ADD_STOCK_LINE_ADD:
    //   return Object.assign({}, state, {
    //     searchMessage:null
    //     , searchMessageSeverity:null
    //     , searchResults:null
    //   });
  
    case types.GET_GRN_STARTED:
      return Object.assign({}, state, {
        docMessage:"Loading GRN " + payload.GRN_number + "..."
        , docMessageSeverity:FEEDBACK_WAITING
        , doc:null
        , lines: null
      });
      
    case types.GET_GRN_FAILED:
      return Object.assign({}, state, {
        docMessage:payload.message
        , docMessageSeverity:payload.severity
      });
      
    case types.SET_GRN_VALUES:
      return Object.assign({}, state, {
        doc:Object.assign({}, state.doc, payload.values)
      });
      
    case types.GET_GRN_COMPLETE:
      return Object.assign({}, state, {
        docMessage:null
        , docMessageSeverity:null
        , lockMessage:null
        , lockMessageSeverity:null
        , doc:payload.doc
        , lines: null
      });
      
    case types.LOCK_GRN_STARTED:
      return Object.assign({}, state, {
        lockMessage:"Locking GRN " + payload.GRN_number + "..."
        , lockMessageSeverity:FEEDBACK_WAITING
      });
      
    case types.LOCK_GRN_FAILED:
      return Object.assign({}, state, {
        lockMessage:payload.message
        , lockMessageSeverity:payload.severity
      });
      
    case types.LOCK_GRN_COMPLETE:
      return Object.assign({}, state, {
        lockMessage:"Locked ok."
        , lockMessageSeverity:FEEDBACK_MESSAGE
      });
      
    case types.UNLOCK_GRN_STARTED:
      return Object.assign({}, state, {
        docMessage:"UnLocking GRN " + payload.GRN_number + "..."
        , docMessageSeverity:FEEDBACK_WAITING
      });
      
    case types.UNLOCK_GRN_FAILED:
      return Object.assign({}, state, {
        docMessage:payload.message
        , docMessageSeverity:payload.severity
      });
     
    case types.UNLOCK_GRN_COMPLETE:
      return Object.assign({}, state, {
        docMessage:"Locked ok."
        , docMessageSeverity:FEEDBACK_MESSAGE
      });
      
    case types.LOADING_GRN_LINES_STARTED:
      return Object.assign({}, state, {
        linesMessage:"Loading lines..."
        , linesMessageSeverity:FEEDBACK_WAITING
        , lines:null
      });
      
    case types.LOADING_GRN_LINES_FAILED:
      return Object.assign({}, state, {
        linesMessage:payload.message
        , linesMessageSeverity:payload.severity
      });
      
    case types.LOADING_GRN_LINES_COMPLETE:
      return Object.assign({}, state, {
        linesMessage:null
        , linesMessageSeverity:FEEDBACK_MESSAGE
        , lines:payload.lines
      });
      
    case types.LOADING_GRN_LINE_STARTED:
      return Object.assign({}, state, {
        lineMessage:"Loading line..."
        , lineMessageSeverity:FEEDBACK_WAITING
        , line:null
        , docMessage: null
      });
      
    case types.LOADING_GRN_LINE_FAILED:
      return Object.assign({}, state, {
        lineMessage:payload.message
        , lineMessageSeverity:payload.severity
      });
      
    case types.LOADING_GRN_LINE_COMPLETE:
      return Object.assign({}, state, {
        lineMessage:null
        , lineMessageSeverity:null
        , line:payload.line
      });
      
    case types.CLEAR_GRN_LINE:
      return Object.assign({}, state, {
        lineMessage:null
        , lineMessageSeverity:null
        , line:null
        , lineSavedOK:null
      });
      
    case types.GRN_LINE_SET_PART:
      // adjust line based on part type
      let rotable = payload.part && payload.part.rotable == 1

      let newLineSource = {}

      if(payload.part){
        newLineSource.part = payload.part
        newLineSource.ta_id = 0
        newLineSource.ta_entry_id = 0
        newLineSource.quantity = rotable ? "1.00" : null
        newLineSource.serial_number = ''      
      } else {
        newLineSource.part = {}
      }

      if(payload.taLine){
        rotable = Number(payload.taLine.partrotable)
        newLineSource.part = {
          itemid : payload.taLine.part_id
          , rotable : payload.taLine.partrotable
          , invoicepartno : payload.taLine.part
          , name : payload.taLine.partdescription
          }
        newLineSource.ta_id = payload.taLine.ta_id
        newLineSource.ta_entry_id = payload.taLine.entry_id
        newLineSource.quantity = rotable ? "1.00" : payload.taLine.quantity
        newLineSource.condition_id = payload.taLine.condition_id
        newLineSource.serial_number = payload.taLine.serial_number
      }

      return Object.assign({}, state, {
        line: Object.assign({}, state.line, newLineSource)
      })

    case types.NEW_GRN_LINE_STARTED:
      return Object.assign({}, state, {
        lineMessage:null
        , lineMessageSeverity:null
        , line:{
          GRN_id:state.doc.GRN_id
          , transaction_id:12 // Customer stock
          , condition_id: 14 // new
          , os_cust_exch: 0
        }
      });
      
      // case types.EDIT_GRN_FORM_SUBMIT_STARTED:
      //   return Object.assign({}, state, {
      //       daNewStatus:newStatus(state.da.status, payload.newStatus)
      //     });
      //   
      case types.UPDATE_GRN_STARTED:
        return Object.assign({}, state, {
              docMessage:"Saving..."
              , docMessageSeverity:FEEDBACK_WAITING
          });
        
      case types.UPDATE_GRN_FAILED:
        return Object.assign({}, state, {
            docMessage:payload.message
            , docMessageSeverity:payload.severity
          });
        
      case types.UPDATE_GRN_COMPLETE:
        return Object.assign({}, state, {
            docMessage:null
            , docMessageSeverity:null
          });
        
      case types.SAVE_GRN_LINE_STARTED:
        return Object.assign({}, state, {
              lineMessage:"Saving..."
              , lineMessageSeverity:FEEDBACK_WAITING
          });
        
      case types.SAVE_GRN_LINE_FAILED:
        return Object.assign({}, state, {
            lineMessage:payload.message
            , lineMessageSeverity:payload.severity
          });
        
      case types.SAVE_GRN_LINE_COMPLETE:
        return Object.assign({}, state, {
            lineMessage:"Saved ok"
            , lineMessageSeverity:FEEDBACK_MESSAGE
            , line:null
            , lineSavedOK: true
            , docMessage:"Line saved ok"
            , docMessageSeverity:FEEDBACK_MESSAGE
            });
        
      case types.LOADING_GRN_CUSTOMER_STARTED:
        return Object.assign({}, state, {
          loadingCustomerMessage:"Laoding customer..."
          , loadingCustomerMessageSeverity:FEEDBACK_WAITING
        });
        
      case types.LOADING_GRN_CUSTOMER_FAILED:
        return Object.assign({}, state, {
          loadingCustomerMessage:payload.message
          , loadingCustomerMessageSeverity:payload.severity
        });
        
      case types.LOADING_GRN_CUSTOMER_COMPLETE:
        return Object.assign({}, state, {
          loadingCustomerMessage:payload.warning
          , loadingCustomerMessageSeverity:payload.warning ? payload.severity : null
        });
        
      case types.DELETE_GRN_LINE_STARTED:
        return Object.assign({}, state, {
              lineMessage:"Deleting..."
              , lineMessageSeverity:FEEDBACK_WAITING
          });
        
      case types.DELETE_GRN_LINE_FAILED:
        return Object.assign({}, state, {
            lineMessage:payload.message
            , lineMessageSeverity:payload.severity
          });
        
      case types.DELETE_GRN_LINE_COMPLETE:
        return Object.assign({}, state, {
            lineMessage:null
            , lineMessageSeverity:null
            , line:null
            , lines:null
            , docMessage: "Line deleted"
            , docMessageSeverity: FEEDBACK_MESSAGE
          });
        
      case types.DELETE_GRN_STARTED:
        return Object.assign({}, state, {
              docMessage:"Deleting..."
              , docMessageSeverity:FEEDBACK_WAITING
          });
        
      case types.DELETE_GRN_FAILED:
        return Object.assign({}, state, {
            docMessage:payload.message
            , docMessageSeverity:payload.severity
          });
        
      case types.DELETE_GRN_COMPLETE:
        return Object.assign({}, state, {
            docMessage:null
            , docMessageSeverity:null
            , doc:null
            , message:"GRN and lines deleted"
            , messageSeverity:FEEDBACK_MESSAGE
          });

      case types.CLEAR_ALL_GRN_MESSAGES:
          return Object.assign({}, state, {
            message:null,
            mesageSeverity:null,
            searchMessage:null,
            searchMesageSeverity:null,
            docMessage:null,
            docMessageSeverity:null,
            noGRNsMessage:null,
            noGRNsSeverity:null
            });

      default:
        return state;
  }
}
