import React from 'react';
import { Link } from 'react-router-dom'; //Not LINK or activeClassName will break

// note we prefix the route with "/" unless the route == "/"
const NavItem = ({key,to,label}, menuItemClicked, activeItem) => (
  <li key={key} className={activeItem === to ? 'active' : null}>
    <Link
      to={to}
      onClick={menuItemClicked}
      >{label}</Link>
  </li>
)

export default NavItem;
