import * as types from '../actions/types'
import { FEEDBACK_WAITING } from '../lib/message-types'

const INITIAL_STATE = {};

let loadUserEventCounter = 0;

export default (state = INITIAL_STATE, {type, payload}) => {
  switch (type) {
    case types.LOOKUP_USERS_STARTED:
      return Object.assign({}, state, {
        message: "Loading users..."
        , messageSeverity:FEEDBACK_WAITING
        , users: null
      });
      
    case types.LOOKUP_USERS_FAILED:
      return Object.assign({}, state, {
        message: payload.message
        , messageSeverity:payload.severity
      });
      
    case types.LOOKUP_USERS_COMPLETE:
      return Object.assign({}, state, {
        message: null
        , messageSeverity:null
        , users: payload.users
      });
      
    case types.LOAD_USER_STARTED:
      return Object.assign({}, state, {
        message: "Loading user..."
        , messageSeverity:FEEDBACK_WAITING
        , users: null
        , user: null
      });
      
    case types.LOAD_USER_FAILED:
      return Object.assign({}, state, {
        message: payload.message
        , messageSeverity:payload.severity
      });
      
    case types.LOAD_USER_COMPLETE:
      return Object.assign({}, state, {
        message: null
        , messageSeverity:null
        , user: payload.user
        , loadUserEventCounter: loadUserEventCounter++
      });
      
    case types.UPDATE_USER_STARTED:
      return Object.assign({}, state, {
        message: "Updating user..."
        , messageSeverity:FEEDBACK_WAITING
        , users: null
      });
      
    case types.UPDATE_USER_FAILED:
      return Object.assign({}, state, {
        message: payload.message
        , messageSeverity:payload.severity
      });
      
    case types.UPDATE_USER_COMPLETE:
      return Object.assign({}, state, {
        message: null
        , messageSeverity:null
      });
      
    case types.LOOKUP_USERS_CLEAR:
      return INITIAL_STATE;
      
    default:
      return state;
  }
}
