import * as types from '../actions/types'
import {
  FEEDBACK_MESSAGE
  , FEEDBACK_WAITING
  , FEEDBACK_WARNING } from '../lib/message-types'

const INITIAL_STATE = {lookupHistory:[]}

const LOOKUP_HISTORY_LENGTH = 5
export default (state = INITIAL_STATE, {type, payload}) => {
  switch (type) {
    case types.LOOKUP_PARTS_STARTED:
      return Object.assign({}, state, {
        lookupMessage: "Loading parts..."
        , lookupMessageSeverity:FEEDBACK_WAITING
        , lookupParts: null
      });
      
    case types.LOOKUP_PARTS_FAILED:
      return Object.assign({}, state, {
        lookupMessage: payload.message
        , lookupMessageSeverity:payload.severity
      });
      
    case types.LOOKUP_PARTS_COMPLETE:
      return Object.assign({}, state, {
        lookupMessage: null
        , lookupMessageSeverity:null
        , lookupParts: payload.parts
        , lookupPartsPage: payload.page
      });
      
    case types.LOAD_LOOKUP_PART_STARTED:
      return Object.assign({}, state, {
        lookupLoadMessage: "Loading part..."
        , lookupLoadMessageSeverity:FEEDBACK_WAITING
      });
      
    case types.LOAD_LOOKUP_PART_FAILED:
      return Object.assign({}, state, {
        lookupLoadMessage: payload.message
        , lookupLoadMessageSeverity:payload.severity
      });
      
    case types.LOAD_LOOKUP_PART_COMPLETE:

      let lookupHistory = state
        .lookupHistory
        .filter( part => part.itemid != payload.part.itemid )
        .slice(0, LOOKUP_HISTORY_LENGTH - 1)

      lookupHistory.unshift({
        itemid:payload.part.itemid
        , invoicepartno: payload.part.invoicepartno
        , name: payload.part.name
       })

      return Object.assign({}, state, {
        lookupLoadMessage: null
        , lookupLoadMessageSeverity:null
        , lookupParts: null
        , lookupHistory
      });
      
    case types.LOOKUP_PARTS_CLEAR:
      return Object.assign({}, state, {
        lookupLoadMessage: null
        , lookupLoadMessageSeverity:null
        , lookupParts: null
      });
      
    case types.CREATE_PART_STARTED:
      return Object.assign({}, state, {
        createMessage: "Creating part..."
        , createMessageSeverity:FEEDBACK_WAITING
      });
      
    case types.CREATE_PART_FAILED:
      return Object.assign({}, state, {
        createMessage: payload.message
        , createMessageSeverity:payload.severity
      });
      
    case types.CREATE_PART_COMPLETE:
      return Object.assign({}, state, {
        createMessage: null
        , createMessageSeverity:null
        , lookupParts: null
      });
      
    case types.GET_PART_STARTED:
      return Object.assign({}, state, {
        message: "Loading part..."
        , messageSeverity:FEEDBACK_WAITING
        , part: null
      });
      
    case types.GET_PART_FAILED:
      return Object.assign({}, state, {
        message: payload.message
        , messageSeverity:payload.severity
      });
      
    case types.SET_PART:
      return Object.assign({}, state, {
        message: null
        , messageSeverity:null
        , part: payload.part
      });
      
    case types.CLEAR_PART:
      return Object.assign({}, state, {
        message: null
        , messageSeverity:null
        , part: null
      });
      
    case types.DELETE_PART_STARTED:
      return Object.assign({}, state, {
        deleteMessage: "Deleting part..."
        , deleteMessageSeverity:FEEDBACK_WAITING
      });
      
    case types.DELETE_PART_FAILED:
      return Object.assign({}, state, {
        deleteMessage: payload.message
        , deleteMessageSeverity:payload.severity
      });
      
    case types.DELETE_PART_COMPLETE:
      return Object.assign({}, state, {
       deleteMessage: `Part id ${payload.id} deleted ok`
        , deleteMessageSeverity:FEEDBACK_MESSAGE
        , lookupHistory: state
          .lookupHistory
          .filter( part => part.itemid != payload.id )
      });
      
    case types.DELETE_PART_CLEAR:
      return Object.assign({}, state, {
        deleteMessage: null
        , deleteMessageSeverity:null
      });
      
    default:
      return state;
  }
}
