import * as types from '../actions/types'
import { getMenus } from '../lib/loginTokenManager'

const initialState = () =>{
  return {items : getMenus(), mainMenuExpanded:false, secondMenuExpanded:false};
}

export const reducer = (state = initialState(), {type, payload}) => {
  switch (type) {
    case types.SET_MENUS:
      return Object.assign({}, state, { items: payload });
      
    case types.MAINMENU_EXPANDED:
      return Object.assign({}, state, { mainMenuExpanded: payload.expanded });
      
    case types.SECONDMENU_EXPANDED:
      return Object.assign({}, state, { secondMenuExpanded: payload.expanded });
      
    default:
      return state;
  }
}
