import * as types from '../actions/types'
import { FEEDBACK_WAITING } from '../lib/message-types'

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, {type, payload}) => {
  switch (type) {
    case types.LOOKUP_SOURCE_TA_LINES_STARTED:
      return Object.assign({}, state, {
        message: "Loading source TA lines..."
        , messageSeverity:FEEDBACK_WAITING
        , lines: null
        , doc: null
      });
      
    case types.LOOKUP_SOURCE_TA_LINES_FAILED:
      return Object.assign({}, state, {
        message: payload.message
        , messageSeverity:payload.severity
      });
      
    case types.LOOKUP_SOURCE_TA_LINES_COMPLETE:
      return Object.assign({}, state, {
        message: null
        , messageSeverity:null
        , lines: payload.lines
        , doc: payload.doc
      });
      
    case types.LOOKUP_SOURCE_TA_LINES_CLEAR:
      return INITIAL_STATE;
      
    default:
      return state;
  }
}
