import * as types from '../actions/types'
import { FEEDBACK_WAITING, FEEDBACK_MESSAGE } from '../lib/message-types'

const initialState = () => ({})

export default (state = initialState(), {type, payload}) => {

  switch (type) {
    case types.CHECK_API_STARTED:
      return Object.assign({}, state, {
        checkAPIMessage:"Testing connection to Raptor"
        , checkAPISeverity:FEEDBACK_WAITING
      });
      
    case types.CHECK_API_FAILED:
      return Object.assign({}, state, {
        checkAPIMessage:payload.message
        , checkAPIMessageSeverity:payload.severity
      });
      
    case types.CHECK_API_COMPLETE:
      return Object.assign({}, state, {
        checkAPIMessage:"Raptor API connected OK"
        , checkAPIMessageSeverity:FEEDBACK_MESSAGE
        , apiVersion: payload.response.version
      });
      
    case types.GET_CONNECTIONS_STARTED:
      return Object.assign({}, state, {
        connectionsMessage:"Testing connection to Raptor"
        , connectionsSeverity:FEEDBACK_WAITING
      });
      
    case types.GET_CONNECTIONS_FAILED:
      return Object.assign({}, state, {
        connectionsMessage:payload.message
        , connectionsMessageSeverity:payload.severity
      });
      
    case types.GET_CONNECTIONS_COMPLETE:
      return Object.assign({}, state, {
        connectionsMessage:null
        , connectionsMessageSeverity:null
        , connections: payload
      });
      
      default:
      return state;
  }
}
