import * as types from './types'

export const setMenus = (items) => ({
  type: types.SET_MENUS
  , payload : items
})

export const setMainMenuExpanded = expanded => ({
  type: types.MAINMENU_EXPANDED
  , payload : { expanded }
})

export const setSecondMenuExpanded = expanded => ({
  type: types.SECONDMENU_EXPANDED
  , payload : { expanded }
})
