export const FEEDBACK_MESSAGE = "message"
export const FEEDBACK_WAITING = "waiting"
export const FEEDBACK_WARNING = "warning"
export const FEEDBACK_ERROR = "error"

export class Warning extends Error{
   constructor(...args){
     super(...args);
   }
}

export const actionMessage = err => ({
  message : err.message
  , severity: errorType(err)
}) 

export const errorType = err => {
  let t = FEEDBACK_ERROR;
  try{
    t = err instanceof Warning ? FEEDBACK_WARNING : FEEDBACK_ERROR
    if(t !== FEEDBACK_WARNING){
      console.error(err)
    }
  }catch(e){
    console.error(e)
  }
  return t
}
