//import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import configureStore from './store/configureStore';
import initialState from './store/initialState.js';
import Root from './containers/Root';

const store = configureStore(initialState);

render(
	<AppContainer warnings={false}>
		<Root store={store}/>
	</AppContainer>,
	document.getElementById('raptor')
);

if (module.hot) {
	module.hot.accept('./containers/Root', () => {
		const Root = require('./containers/Root').default;
		render(
      <AppContainer warnings={false}>
			<Root store={store}/>
		</AppContainer>,
		document.getElementById('raptor')
		);
	});
}
