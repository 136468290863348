import * as types from '../actions/types'
import { getUser } from '../lib/loginTokenManager'
import { FEEDBACK_WAITING } from '../lib/message-types'

const initialState = () => {
  let state =  {
    loggingIn:false
    , loginMessage:""
    , loginError:null
    , user : getUser()
  };

  // WHAT?
  state.loggedIn = state.user != null;

  return state;
}

export const reducer = (state = initialState(), {type, payload}) => {

  switch (type) {
    case types.SET_USER_PROFILE:
      return Object.assign({}, state, {
            loggedIn:true
            , loggingIn:false
            , loginMessage:null
            , loginMessageType:null
            , loginError:null
            , user: payload
        });
      
    case types.LOGIN_FAILED:
      return Object.assign({}, state, {
            loggedIn:false
            , loggingIn:false
            , loginMessage:payload.message
            , loginMessageType:payload.severity
            , loginError:payload.loginError
            , user: {}
        });
      
    case types.LOGIN_FORM_SUBMITTED:
      return Object.assign({}, state, {
            loggedIn:false
            , loggingIn:true
            , loginMessage:"Logging you in..."
            , loginMessageType:FEEDBACK_WAITING
            , loginError:null
            , user: {}
        });
    case types.RENEW_TOKEN_STARTED:
      return Object.assign({}, state, {
            loggingIn:true
            , loginMessage:"Renewing user token..."
            , loginMessageType:FEEDBACK_WAITING
            , loginError:null
            , user: {}
        });
    case types.LOAD_LOGIN_HISTORY_STARTED:
      return Object.assign({}, state, {
        loadLoginHistoryMessage:"Loading login history..."
        , loadLoginHistorySeverity:FEEDBACK_WAITING
      });
      
    case types.LOAD_LOGIN_HISTORY_FAILED:
      return Object.assign({}, state, {
        loadLoginHistoryMessage:payload.message
        , loadLoginHistoryMessageSeverity:payload.severity
      });
      
    case types.LOAD_LOGIN_HISTORY_COMPLETE:
      return Object.assign({}, state, {
        loadLoginHistoryMessage:null
        , loadLoginHistoryMessageSeverity:null
        , loginHistory: payload.lines
      });
      
    default:
      return state;
  }
}
