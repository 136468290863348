import React from 'react';
import {
  FEEDBACK_MESSAGE
  , FEEDBACK_WAITING
  , FEEDBACK_WARNING
  , FEEDBACK_ERROR
} from '../lib/message-types'

const icons = {}
icons[FEEDBACK_MESSAGE] = "\u263A"
icons[FEEDBACK_WAITING] = "\u231B"
icons[FEEDBACK_WARNING] = "\u26A0"
icons[FEEDBACK_ERROR] = "\u2620"

export const FeedbackMessage = ({
  message, 
  type = FEEDBACK_MESSAGE, 
  onClick,
  onClickText = 'click to clear'}) => {

  if(!message){ return null }

  let msg = typeof message != "String" ? message.toString() : message

  let overlayClassName = type === FEEDBACK_WAITING ? "overlay-obscure" : null;

  let clickable = type !== FEEDBACK_WAITING && onClick

  return (
    <div className={overlayClassName}>
      <div
        className={"feedback " + type}
        onClick={ev => {
          ev.preventDefault(); 
          clickable && onClick()
        }}
        >
        <span className="feedback-icon">{icons[type]}</span>
        <span className={"feedback-message" + (clickable ? ' clickable' : '')}>
          {msg}
          {clickable ? ' ['+onClickText+']' : null}
        </span>
        <span className="feedback-icon">{icons[type]}</span>
      </div>
    </div>
  )
}

export const InlineWarningFlag = ({message = " INVALID"}) => (
  <span className="feedback warning">
    <span className="feedback-icon">{icons[FEEDBACK_WARNING]}</span>
    { message }
  </span>
)
