//import { routerReducer as routing } from 'react-router-redux';
import { combineReducers } from 'redux';
import { reducer as loginReducer } from './login'
import { reducer as menuReducer } from './menu'
import { reducer as recentDocumentsReducer } from './recentDocuments'
import { reducer as itemsReducer } from './items'
import sourceDALines from './sourceDALines'
import sourceTALines from './sourceTALines'
import stockHistory from './stockHistory'
import stockEnquiry from './stockEnquiry'
import parts from './parts'
import users from './users'
import addingStockLine from './addingStockLine'
import quotes from './quotes'
import tas from './tas'
import das from './das'
import system from './system'
import grns from '../modules/GRNs/reducer' 
import forms from './forms' // final-form reducer workaround
import {LOGOFF, LOGGED_IN} from '../actions/types'

const appReducer = combineReducers({
  auth: loginReducer
  , menu: menuReducer
  , recentDocuments: recentDocumentsReducer
  , items: itemsReducer
  , sourceDALines
  , sourceTALines
  , parts
  , stockHistory
  , stockEnquiry
  , users
  , addingStockLine
  , quotes
  , tas
  , das
  , system
  , grns
  , forms
});

const rootReducer = (state, action) => {
  if(action.type === LOGOFF || action.type === LOGGED_IN){
    state = undefined
  }
  return appReducer(state, action);
}

export default rootReducer;
