import { token as config } from '../config'

const TOKEN_NAME = config.name;

const urlBase64Decode = str => {
   var output = str.replace('-', '+').replace('_', '/');
   switch (output.length % 4) {
       case 0:
           break;
       case 2:
           output += '==';
           break;
       case 3:
           output += '=';
           break;
       default:
           output = '';
   }
   try{
     return window.atob(output);
   }catch(ex){
     return '';
   }
}

function getProfileFromToken(t){
  if(!t){
    return null
  }
  let encoded = t.split('.')[1];
  let temp = JSON.parse(urlBase64Decode(encoded)).payload;
  return {
    userId: temp.userid
   , userName: temp.initials
   , fullName: temp.fullname
   , email: temp.email
   , initials: temp.initials
   , permissions: temp.permissions
   , branchId: temp.branchid
   , timezone: 'GMT'
   , telephone: temp.telephone
  }
}

export const addAuthHeader = headers => {
  if(localStorage[TOKEN_NAME]){
    headers[config.header] = localStorage[TOKEN_NAME];
  }
  return headers;
}

export const getUser = () => {
  let t = localStorage[TOKEN_NAME]
  , user
  ;

  if (typeof t !== 'undefined') {
     try{
      user = getProfileFromToken(t)
      }catch(ex){
        console.warn("Invalid token")
     }
  }
  return user;

  // return {
  //   userId:1 //users.userid in database
  //   , userName:"test"
  //   , fullName:"Test User"
  //   , email:"test@easilyamused.uk"
  //   , initials: "ADM"
  //   , branchId:1
  //   , permissions:"1111111111111111111111111111111111111111111111111111111111111111111"
  //   , timezone: "GMT"
  // };
}

export const killToken = () => {
  console.warn("Killing token")
  localStorage.removeItem(TOKEN_NAME);
}

const setToken = (token) => {
  if(!token){
    return killToken();
  }
  localStorage[TOKEN_NAME] = token;
}

export const tokenIsInvalid = message => message === TOKEN_INVALID_MESSAGE 
  || message === TOKEN_EXPIRED_MESSAGE

  export const TOKEN_EXPIRED_MESSAGE = 'Token expired'
  export const TOKEN_INVALID_MESSAGE = 'Token invalid'

export const extractResponseToken = (response) => {
  let token = response.headers.get(TOKEN_NAME)
  if(token){
    setToken(token)
  }
}

export const checkResponseFailedInvalidToken = (status, message) => {
  if(status === 401
    && message == TOKEN_INVALID_MESSAGE ){
    throw new Error(TOKEN_INVALID_MESSAGE);
  }
}

export const getMenus = () => {

  if(!getUser()){
    return [
      {key: "mm-login", to:"login", label:"Login"}
      , {key: "mm-about" , to:"about", label:"About"}
    ];
  }

  return [
      {to:"/",label:"Home"}
      // , {to:"grns",label:"GRNs"}
      , {to:"/grns",label:"GRNs"}
      , {to:"/tas",label:"TAs"}
      , {to:"/das",label:"DAs"}
      , {to:"/quotes",label:"Quotes"}
      , {to:"/parts",label:"Parts"}
      , {to:"/history",label:"History"}
      , {to:"/enquiry",label:"Enquiry"}
      // , {to:"setup",label:"Setup", children:[
      //     {to:"customers",label:"Customers"}
      //     , {to:"suppliers",label:"Suppliers"}
      //     , {to:"branches",label:"Branches"}
           , {to:"/users",label:"Users"}
      //     , {to:"customergrades",label:"Customer Grades"}
      //     , {to:"countries",label:"Countries"}
      //     , {to:"regnotesaircrafttypes",label:"Reg Notes Aircraft Types"}
      //     , {to:"aircraftjobtitles",label:"Aircraft Job Titles"}
      //     , {to:"aircrafttypes",label:"Aircraft Types"}
      //     , {to:"operators",label:"Operators"}
      //     , {to:"deals",label:"Deals"}
      //     , {to:"partconditions",label:"Part Conditions"}
      //     , {to:"hazardousmaterialtypes",label:"Hazardous Material Types"}
      //     , {to:"paymenttypes",label:"Payment Types"}
      //     , {to:"manufacturers",label:"Manufacturers"}
      //     , {to:"qualitycertificates",label:"Quality Certificates"}
      //     , {to:"lifeunits",label:"Life Units"}
      //     , {to:"stockunits",label:"Stock Units"}
      //     , {to:"shippingmethods",label:"Shipping Methods"}
      //   ]}
      // , {to:"stock",label:"Stock"}
      // , {to:"reports",label:"Reports"}
      , {to:"/logoff",label:"Logoff"}
      , {to:"/about",label:"About"}
    ];
}
