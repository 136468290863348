import * as items from './items'

export const TRANS_SALE = 1
export const TRANS_FLAT_EXCHANGE = 2
export const TRANS_COST_PLUS = 3
export const TRANS_LOAN = 4
export const TRANS_WARRANTY = 5
export const TRANS_CREDIT = 6
export const TRANS_OFF_EXCHANGE = 7
export const TRANS_FAILED_ON_FIT = 8
export const TRANS_NOT_USED = 9
export const TRANS_EMBODIMENT_LOAN = 10
export const TRANS_PURCHASE = 11
export const TRANS_CUSTOMER_PROPERTY = 12
export const TRANS_SCRAP = 13
export const TRANS_LOAN_RETURNED = 14
export const TRANS_TA_STOCK = 15
export const TRANS_PURCHASED = 16
export const TRANS_REPAIR = 17
export const TRANS_REPAIRED = 18
export const TRANS_ROR = 19
export const TRANS_CUSTOMER_REPAIR = 20
export const TRANS_CUSTOMER_BENCH_TEST = 21
export const TRANS_CORE_RETURN = 22
export const TRANS_TRANSFER = 23
export const TRANS_OTHER = 24


export const TRANSACTIONS = {};

TRANSACTIONS[TRANS_SALE] = "Sale"
TRANSACTIONS[TRANS_FLAT_EXCHANGE] = "Flat Exchange"
TRANSACTIONS[TRANS_COST_PLUS] = "Cost Plus Exchange"
TRANSACTIONS[TRANS_LOAN] = "Loan"
TRANSACTIONS[TRANS_WARRANTY] = "Warranty"
TRANSACTIONS[TRANS_CREDIT] = "Credit"
TRANSACTIONS[TRANS_OFF_EXCHANGE] = "Off Exchange"
TRANSACTIONS[TRANS_FAILED_ON_FIT] = "Failed on Fit"
TRANSACTIONS[TRANS_NOT_USED] = "Not Used"
TRANSACTIONS[TRANS_EMBODIMENT_LOAN] = "Embodiment Loan"
TRANSACTIONS[TRANS_PURCHASE] = "Purchase"
TRANSACTIONS[TRANS_CUSTOMER_PROPERTY] = "Customer Stock"
TRANSACTIONS[TRANS_SCRAP] = "Scrap"
TRANSACTIONS[TRANS_LOAN_RETURNED] = "Load Returned"
TRANSACTIONS[TRANS_TA_STOCK] = "TA Stock"
TRANSACTIONS[TRANS_PURCHASED] = "Purchased"
TRANSACTIONS[TRANS_REPAIR] = "Repair"
TRANSACTIONS[TRANS_REPAIRED] = "Repaired"
TRANSACTIONS[TRANS_ROR] = "ROR"
TRANSACTIONS[TRANS_CUSTOMER_REPAIR] = "Customer Repair"
TRANSACTIONS[TRANS_CUSTOMER_BENCH_TEST] = "Customer Bench Test"
TRANSACTIONS[TRANS_CORE_RETURN] = "Core Return"
TRANSACTIONS[TRANS_TRANSFER] = "Transfer"
TRANSACTIONS[TRANS_OTHER] = "Other"


export const getTransactionName = (id) => {
  return TRANSACTIONS[id] || TRANSACTIONS[TRANS_OTHER];
}

const TG_DA_CUSTOMER = [
  ,{value:TRANS_SALE, label:TRANSACTIONS[TRANS_SALE]}
  ,{value:TRANS_COST_PLUS, label:TRANSACTIONS[TRANS_COST_PLUS]}
  ,{value:TRANS_CUSTOMER_PROPERTY, label:TRANSACTIONS[TRANS_CUSTOMER_PROPERTY]}
  ,{value:TRANS_FLAT_EXCHANGE, label:TRANSACTIONS[TRANS_FLAT_EXCHANGE]}
  ,{value:TRANS_LOAN, label:TRANSACTIONS[TRANS_LOAN]}
  ,{value:TRANS_OTHER, label:TRANSACTIONS[TRANS_OTHER]}
  ]
const TG_DA_SUPPLIER = [
  {value:TRANS_EMBODIMENT_LOAN, label:TRANSACTIONS[TRANS_EMBODIMENT_LOAN]}
  ,{value:TRANS_FAILED_ON_FIT, label:TRANSACTIONS[TRANS_FAILED_ON_FIT]}
  ,{value:TRANS_LOAN_RETURNED, label:TRANSACTIONS[TRANS_LOAN_RETURNED]}
  ,{value:TRANS_NOT_USED, label:TRANSACTIONS[TRANS_NOT_USED]}
  ,{value:TRANS_CORE_RETURN, label:TRANSACTIONS[TRANS_CORE_RETURN]}
  ,{value:TRANS_SCRAP, label:TRANSACTIONS[TRANS_SCRAP]}
  ,{value:TRANS_OTHER, label:TRANSACTIONS[TRANS_OTHER]}
]
const TG_DA_XFER = [
  {value:TRANS_TRANSFER, label:TRANSACTIONS[TRANS_TRANSFER]}
  ,{value:TRANS_OTHER, label:TRANSACTIONS[TRANS_OTHER]}
]
const TG_GRN_0 = [
  {value:TRANS_CUSTOMER_PROPERTY, label:TRANSACTIONS[TRANS_CUSTOMER_PROPERTY]}
  ,{value:TRANS_FAILED_ON_FIT, label:TRANSACTIONS[TRANS_FAILED_ON_FIT]}
  ,{value:TRANS_LOAN_RETURNED, label:TRANSACTIONS[TRANS_LOAN_RETURNED]}
  ,{value:TRANS_NOT_USED, label:TRANSACTIONS[TRANS_NOT_USED]}
  ,{value:TRANS_OFF_EXCHANGE, label:TRANSACTIONS[TRANS_OFF_EXCHANGE]}
  ,{value:TRANS_TA_STOCK, label:TRANSACTIONS[TRANS_TA_STOCK]}
  ,{value:TRANS_OTHER, label:TRANSACTIONS[TRANS_OTHER]}
]
const TG_GRN_TA = [
  {value:TRANS_COST_PLUS, label:TRANSACTIONS[TRANS_COST_PLUS]}
  ,{value:TRANS_FLAT_EXCHANGE, label:TRANSACTIONS[TRANS_FLAT_EXCHANGE]}
  ,{value:TRANS_LOAN, label:TRANSACTIONS[TRANS_LOAN]}
  ,{value:TRANS_PURCHASED, label:TRANSACTIONS[TRANS_PURCHASED]}
  ,{value:TRANS_OTHER, label:TRANSACTIONS[TRANS_OTHER]}
]
const TG_GRN_RO = [
  {value:TRANS_REPAIRED, label:TRANSACTIONS[TRANS_REPAIRED]}
  ,{value:TRANS_OTHER, label:TRANSACTIONS[TRANS_OTHER]}
]
const TG_GRN_ELSE = [
  {value:TRANS_CUSTOMER_PROPERTY, label:TRANSACTIONS[TRANS_CUSTOMER_PROPERTY]}
  ,{value:TRANS_FAILED_ON_FIT, label:TRANSACTIONS[TRANS_FAILED_ON_FIT]}
  ,{value:TRANS_LOAN_RETURNED, label:TRANSACTIONS[TRANS_LOAN_RETURNED]}
  ,{value:TRANS_NOT_USED, label:TRANSACTIONS[TRANS_NOT_USED]}
  ,{value:TRANS_OFF_EXCHANGE, label:TRANSACTIONS[TRANS_OFF_EXCHANGE]}
  ,{value:TRANS_TA_STOCK, label:TRANSACTIONS[TRANS_TA_STOCK]}
  ,{value:TRANS_COST_PLUS, label:TRANSACTIONS[TRANS_COST_PLUS]}
  ,{value:TRANS_FLAT_EXCHANGE, label:TRANSACTIONS[TRANS_FLAT_EXCHANGE]}
  ,{value:TRANS_LOAN, label:TRANSACTIONS[TRANS_LOAN]}
  ,{value:TRANS_PURCHASED, label:TRANSACTIONS[TRANS_PURCHASED]}
  ,{value:TRANS_REPAIRED, label:TRANSACTIONS[TRANS_REPAIRED]}
  ,{value:TRANS_OTHER, label:TRANSACTIONS[TRANS_OTHER]} // combination and type 99?
]
const TG_TA = [
  {value:TRANS_COST_PLUS, label:TRANSACTIONS[TRANS_COST_PLUS]}
  ,{value:TRANS_FLAT_EXCHANGE, label:TRANSACTIONS[TRANS_FLAT_EXCHANGE]}
  ,{value:TRANS_LOAN, label:TRANSACTIONS[TRANS_LOAN]}
  ,{value:TRANS_PURCHASE, label:TRANSACTIONS[TRANS_PURCHASE]}
]
const TG_RO = [
  {value:TRANS_REPAIR, label:TRANSACTIONS[TRANS_REPAIR]}
]
const TG_QUOTE = [
  {value:TRANS_COST_PLUS, label:TRANSACTIONS[TRANS_COST_PLUS]}
  ,{value:TRANS_CUSTOMER_PROPERTY, label:TRANSACTIONS[TRANS_CUSTOMER_PROPERTY]}
  ,{value:TRANS_FLAT_EXCHANGE, label:TRANSACTIONS[TRANS_FLAT_EXCHANGE]}
  ,{value:TRANS_LOAN, label:TRANSACTIONS[TRANS_LOAN]}
  ,{value:TRANS_SALE, label:TRANSACTIONS[TRANS_SALE]}
]
const TG_DEFAULT = [
  {value:TRANS_COST_PLUS, label:TRANSACTIONS[TRANS_COST_PLUS]}
  ,{value:TRANS_CREDIT, label:TRANSACTIONS[TRANS_CREDIT]}
  ,{value:TRANS_CUSTOMER_PROPERTY, label:TRANSACTIONS[TRANS_CUSTOMER_PROPERTY]}
  ,{value:TRANS_EMBODIMENT_LOAN, label:TRANSACTIONS[TRANS_EMBODIMENT_LOAN]}
  ,{value:TRANS_FAILED_ON_FIT, label:TRANSACTIONS[TRANS_FAILED_ON_FIT]}
  ,{value:TRANS_FLAT_EXCHANGE, label:TRANSACTIONS[TRANS_FLAT_EXCHANGE]}
  ,{value:TRANS_LOAN, label:TRANSACTIONS[TRANS_LOAN]}
  ,{value:TRANS_NOT_USED, label:TRANSACTIONS[TRANS_NOT_USED]}
  ,{value:TRANS_OFF_EXCHANGE, label:TRANSACTIONS[TRANS_OFF_EXCHANGE]}
  ,{value:TRANS_PURCHASE, label:TRANSACTIONS[TRANS_PURCHASE]}
  ,{value:TRANS_REPAIR, label:TRANSACTIONS[TRANS_REPAIR]}
  ,{value:TRANS_SALE, label:TRANSACTIONS[TRANS_SALE]}
  ,{value:TRANS_SCRAP, label:TRANSACTIONS[TRANS_SCRAP]}
]

export const getAvailableTransactions = ( type1, type2) => {
  switch(type1){
    case items.SID_DA || items.SID_BOOKINGDAFROMGRN:
      switch(type2){
        case items.SID_CUSTOMER:
          return TG_DA_CUSTOMER;
        case items.SID_SUPPLIER:
          return TG_DA_SUPPLIER;
      }
      return TG_DA_XFER;
      break;
    case items.SID_GRN || items.SID_BOOKINGGRNFROMTA:
      switch(type2){
        case 0 :
          return TG_GRN_0;
        case items.SID_TA:
          return TG_GRN_TA;
        case items.SID_RO:
          return TG_GRN_RO;
      }
      return TG_GRN_ELSE;
    case items.SID_TA:
      return TG_TA;
    case items.SID_RO || items.SID_BOOKINGROFROMGRN:
      return TG_RO;
    case items.SID_QUOTATION:
      return TG_QUOTE;
  }
  return TG_DEFAULT;
}
