import * as types from '../actions/types'
import { FEEDBACK_WAITING, FEEDBACK_WARNING, FEEDBACK_ERROR } from '../lib/message-types'

// state is 3d :( to dynamically handle all items
// repeats for each name
// name:{
//   , failedMessage:null //String = loading
//   , failedSeverity:null //!=null = exception
//   , fetchingMessage:null // String = failed message
//   , cacheId: 0 // integer supplied by api
//   , items: []
// }


const initialState = {}

export const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case types.LOADING_ITEMS_STARTED:
      var newState = {}
      , itemState = {
          fetchingMessage: "Loading " + payload.name + "..."
          , failedMessage: null
          , failureSeverity: null
        };
      if(state[payload.name]){
        itemState = Object.assign({}, state[payload.name], itemState)
      }
      newState[payload.name] = itemState;
      return Object.assign({}, state, newState);
      
    case types.LOADING_ITEMS_FAILED:

    var newState = {};
      newState[payload.name] = {
        fetchingMessage: null
        , failedMessage: payload.message
        , failureSeverity:payload.severity
        , items: null
        , arr : null
        , cacheId: 0
      }
      return Object.assign({}, state, newState);
      
    case types.LOADING_ITEMS_COMPLETE:
      var newState = {};
      newState[payload.name] = {
        fetchingMessage: null
        , failedMessage: null
        , failureSeverity:null
        , items: payload.items
        , arr: payload.arr
        , cacheId: payload.cacheId
      }
      return Object.assign({}, state, newState)
      
    case types.LOADING_ITEMS_NOT_REQUIRED:
      var newState = {}
      , itemState = {
        fetchingMessage: null
        , failedMessage: null
        , failureSeverity:null
      };
      if(state[payload.name]){
        itemState = Object.assign({}, state[payload.name], itemState)
      }
      newState[payload.name] = itemState;
      return Object.assign({}, state, newState);
      
    default:
      return state;
  }
}
