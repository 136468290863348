import * as types from '../actions/types'
import { FEEDBACK_WAITING } from '../lib/message-types'
var moment = require('moment')

const initialState = {
  byMe:[
    // {key:"byme-GRN0123547ADM", type:"grn", fullNumber:"GRN0123547ADM", number:"123547"}
    // , {key:"byme-UGRN0123547ADM", type:"grn", fullNumber:"UGRN0123547ADM", number:"U123547"}
    // , {key:"byme-DA0123547ADM", type:"da", fullNumber:"DA0123547ADM", number:"123547"}
    // , {key:"byme-TA0123547ADM", type:"ta", fullNumber:"TA0123547ADM", number:"123547"}
    // , {key:"byme-MTA0123547ADM", type:"mta", fullNumber:"MTA0123547ADM", number:"123547"}
    // , {key:"byme-RO0123547ADM", type:"ta", fullNumber:"RO0123547ADM", number:"123547"}
  ]
  , byAll:[
    // {key:"byall-GRN0123547ADM", type:"grn", fullNumber:"GRN0123547ADM", number:"123547"}
    // , {key:"byall-UGRN0123547ADM", type:"grn", fullNumber:"UGRN0123547ADM", number:"U123547"}
    // , {key:"byall-DA0123547ADM", type:"da", fullNumber:"DA0123547ADM", number:"123547"}
    // , {key:"byall-TA0123547ADM", type:"ta", fullNumber:"TA0123547ADM", number:"123547"}
    // , {key:"byall-MTA0123547ADM", type:"mta", fullNumber:"MTA0123547ADM", number:"123547"}
    // , {key:"byall-RO0123547ADM", type:"ta", fullNumber:"RO0123547ADM", number:"123547"}
  ]
   , message:null //String = loading
   , messageSeverity:null
   , error:null //!=null = exception
   , fetchingMessage:null // String = failed message
   , expires: null // expires 10 minutes after received
}

export const reducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case types.FETCHING_RECENT_DOCUMENTS:
      return Object.assign({}, state, {
        message: "Fetching recent documents..."
        , messageSeverity:FEEDBACK_WAITING
        , error:null
        , expires:null
      });
      
    case types.FETCHING_RECENT_DOCUMENTS_FAILED:
      return Object.assign({}, state, {
        message: payload.message
        , messageSeverity:payload.severity
        , error:payload.error
        , expires:null
      });
      
    case types.RECEIVED_RECENT_DOCUMENTS:
      return Object.assign({}, state, {
        message: null
        , messageSeverity: null
        , byMe: payload.byMe
        , byAll: payload.byAll
        , failedMessage:null
        , error:null
        , expires: moment().add(10,'minute')
      });
      
    default:
      return state;
  }
}
