export const FORM_NAME_COMMENT_DA_TOP = 'daTop'
export const FORM_NAME_COMMENT_DA_BOTTOM = 'daBottom'
export const FORM_NAME_COMMENT_DA_REMARKS = 'daRemarks'
export const FORM_NAME_COMMENT_DA_INTERNAL = 'daInternal'
export const FORM_NAME_DA_LINE = 'FORM_NAME_DA_LINE'
export const FORM_NAME_EDIT_USER = 'FORM_NAME_EDIT_USER'

export const FORM_NAME_CREATE_QUOTE = 'FORM_NAME_CREATE_QUOTE'
export const FORM_NAME_EDIT_QUOTE = 'FORM_NAME_EDIT_QUOTE'
export const FORM_NAME_SEARCH_QUOTES = 'FORM_NAME_SEARCH_QUOTES'
export const FORM_NAME_LOCK_QUOTE = 'FORM_NAME_LOCK_QUOTE'
export const FORM_NAME_EDIT_QUOTE_LINE = 'FORM_NAME_EDIT_QUOTE_LINE'

export const FORM_NAME_CREATE_TA = 'FORM_NAME_CREATE_TA'
export const FORM_NAME_EDIT_TA = 'FORM_NAME_EDIT_TA'
export const FORM_NAME_EDIT_TA_LINE = 'FORM_NAME_EDIT_TA_LINE'
export const FORM_NAME_SEARCH_TAS = 'FORM_NAME_SEARCH_TAS'

export const FORM_NAME_CREATE_PART = 'FORM_NAME_CREATE_PART'

export const FORM_NAME_CREATE_DA = 'FORM_NAME_CREATE_DA'
export const FORM_NAME_EDIT_DA = 'FORM_NAME_EDIT_DA'
export const FORM_NAME_EDIT_DA_LINE = 'FORM_NAME_EDIT_DA_LINE'
export const FORM_NAME_SEARCH_DAS = 'FORM_NAME_SEARCH_DAS'

export const FORM_NAME_EDIT_GRN = 'FORM_NAME_EDIT_GRN'
export const FORM_NAME_EDIT_GRN_LINE = 'FORM_NAME_EDIT_GRN_LINE'
