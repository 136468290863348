import React, { Component } from 'react';
import { connect } from 'react-redux';
import MainNav from '../components/MainNav';
import { setMainMenuExpanded } from '../../../actions/menus'
import withRouter from '../../../lib/withRouter'

class MainMenu extends Component {
  constructor() {
    super();
    this.state = {};

  }

  getIntialState = () => ({expanded:false})

  _menuExpanderClicked = ev => {
    ev.preventDefault();
    ev.stopPropagation();
    this.props.dispatch(setMainMenuExpanded(!this.props.menu.mainMenuExpanded));
  }

  _menuItemClicked = ev => {
    this.props.dispatch(setMainMenuExpanded(false));
    // ev.preventDefault(); renable if you want to keep menu expanded
    // ev.stopPropagation(); as above
  }

  render = () => (
    <MainNav
      menuItems={ this.props.menu.items }
      onExpanderClick={this._menuExpanderClicked}
      menuItemClicked = {this._menuItemClicked}
      activeItem = {'/'+this.props.location.pathname.split('/')[1]}
      />
  )
}

const mapStateToProps = (state) => ({ menu:state.menu })

export default withRouter(connect(mapStateToProps)(MainMenu));
