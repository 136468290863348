import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import NotFound from '../modules/NotFound'
import ErrorBoundary from './ErrorBoundary'
import MainMenu from '../modules/MainMenu/containers/MainMenu'

const LoginScreen = lazy(() => import('../modules/Login/containers/LoginScreen'))
const TAs = lazy(() => import('../modules/TAs'))
const GRNs = lazy(() => import('../modules/GRNs'))
const Parts = lazy(() => import('../modules/Parts'))
const Quotes = lazy(() => import('../modules/Quotes'))
const DAs = lazy(() => import('../modules/DAs'))
const Users = lazy(() => import('../modules/Users'))
const StockHistory = lazy(() => import('../modules/StockHistory'))
const StockEnquiry = lazy(() => import('../modules/StockEnquiry'))
const About = lazy(() => import('../modules/About'))
const Home = lazy(() => import('../modules/Home/containers/HomeScreen'))
const Logoff = lazy(() => import('../modules/Logoff'))
const NoQuotes = lazy(() => import('../modules/NoQuotes/containers/Router'))
//const System = lazy(() => import('../modules/System/components/SystemRouter'))

const App = ({isLoggedIn, secondMenuExpanded, mainMenuExpanded}) =>
  <BrowserRouter>
    <div id="viewport" className={(mainMenuExpanded ? "main-menu-expanded" : "") + (secondMenuExpanded ? " second-menu-expanded":"")} >
      <MainMenu/>
      <ErrorBoundary>
        <div id="body">
          <Suspense fallback={<div>loading...</div>}>
            <Routes>
              <Route path="/" element={
                <PrivateRoute Com={Home} isLoggedIn={isLoggedIn} />
              }/>
              <Route path="/login" element={<LoginScreen />}/>
              <Route path="/about" element={<About />}/>
              <Route path="/logoff" element={
                <PrivateRoute Com={Logoff} isLoggedIn={isLoggedIn} />
              }/>
              <Route path="/users/*" element={
                <PrivateRoute Com={Users} isLoggedIn={isLoggedIn} />
              }/>
              <Route path="/history/*" element={
                <PrivateRoute Com={StockHistory} isLoggedIn={isLoggedIn} />
              }/>
              <Route path="/enquiry/*" element={
                <PrivateRoute Com={StockEnquiry} isLoggedIn={isLoggedIn} />
              }/>
              <Route path="/grns/*" element={
                <PrivateRoute Com={GRNs} isLoggedIn={isLoggedIn}/>
              }/>
              <Route path="/tas/*" element={
                <PrivateRoute Com={TAs} isLoggedIn={isLoggedIn}/>
              }/>
              <Route path="/das/*" element={
                <PrivateRoute Com={DAs} isLoggedIn={isLoggedIn} />
              } />
              <Route path="/quotes/*" element={
                <PrivateRoute Com={Quotes} isLoggedIn={isLoggedIn} />
              } />
              <Route path="/parts/:partId/no-quotes" element={
                <PrivateRoute Com={NoQuotes} isLoggedIn={isLoggedIn} />
              } />
              <Route path="/parts/*"  element={
                <PrivateRoute Com={Parts} isLoggedIn={isLoggedIn} />
              } />
              <Route path="/no-quotes/*" element={
                <PrivateRoute Com={NoQuotes} isLoggedIn={isLoggedIn} />
              } />
              {/*<PrivateRoute path="/system" isLoggedIn={isLoggedIn} element={System} />*/}
              <Route element={NotFound} />
            </Routes>
          </Suspense>
        </div>
      </ErrorBoundary>
    </div>
  </BrowserRouter>

export default App;
