import * as types from '../actions/types'
import {FEEDBACK_WAITING, FEEDBACK_MESSAGE} from '../lib/message-types'
import { TRANS_REPAIR } from '../lib/transactions'

const initialState = {
  // searchMessage : null
  // , searchSeverity : null
  // , searchResults : null
  // , createMessage: null
  // , createMessageSeverity: null
  // , docMessage:null
  // , docMessageSeverity: null
  // , doc: null
  // , lines:{}
  // , linesMessage:null
  // , linesMessageSeverity:null
  // , linesLoaded:null
  // , line:null
  // , lineMessage:null
  // , lineMessageSeverity:null
  // , lookupSourceGRNLine:null
}

export default (state = initialState, {type, payload}) => {

  switch (type) {
      
    case types.CREATE_TA_FORM_SUBMITTED:
      return Object.assign({}, state, {
        createMessage:"Creating TA..."
        , createMessageSeverity:FEEDBACK_WAITING
      });
      
    case types.CREATE_TA_FAILED:
      return Object.assign({}, state, {
        createMessage:payload.message
        , createMessageSeverity:payload.severity
      });
      
    case types.CREATE_TA_COMPLETE:
      return Object.assign({}, state, {
        createMessage:null
        , createMessageSeverity:null
      });
      
    case types.TAS_SEARCH_STARTED:
      return Object.assign({}, state, {
        searchMessage:"Searching..."
        , searchMessageSeverity:FEEDBACK_WAITING
        , searchResults:null
      });
      
    case types.TAS_SEARCH_FAILED:
      return Object.assign({}, state, {
        searchMessage:payload.message
        , searchMessageSeverity:payload.severity
        , searchResults:null
      });
     
    case types.TAS_SEARCH_COMPLETE:
      let sm, sms;
      if(payload.results.length==0){
        sm = "No results, try with a wilcard '*' on the end";
        sms = FEEDBACK_MESSAGE;
      }
      return Object.assign({}, state, {
        searchMessage:sm
        , searchMessageSeverity:sms
        , searchResults:payload.results
      });

    case types.ADD_STOCK_LINE_ADD:
      return Object.assign({}, state, {
        searchMessage:null
        , searchMessageSeverity:null
        , searchResults:null
      });
 
    case types.GET_TA_STARTED:
      return Object.assign({}, state, {
        docMessage:"Loading TA " + payload.ta_number + "..."
        , docMessageSeverity:FEEDBACK_WAITING
        , doc:null
        , lines: null
      });
      
    case types.GET_TA_FAILED:
      return Object.assign({}, state, {
        docMessage:payload.message
        , docMessageSeverity:payload.severity
      });
      
    case types.SET_TA_VALUES:
      return Object.assign({}, state, {
        doc:Object.assign({}, state.doc, payload.values)
      });
      
    case types.GET_TA_COMPLETE:
      return Object.assign({}, state, {
        docMessage:null
        , docMessageSeverity:null
        , lockMessage:null
        , lockMessageSeverity:null
        , doc:payload.doc
        , lines: null
      });
      
    case types.LOCK_TA_STARTED:
      return Object.assign({}, state, {
        lockMessage:"Locking TA " + payload.ta_number + "..."
        , lockMessageSeverity:FEEDBACK_WAITING
      });
      
    case types.LOCK_TA_FAILED:
      return Object.assign({}, state, {
        lockMessage:payload.message
        , lockMessageSeverity:payload.severity
      });
      
    case types.LOCK_TA_COMPLETE:
      return Object.assign({}, state, {
        lockMessage:"Locked ok."
        , lockMessageSeverity:FEEDBACK_MESSAGE
      });
      
    case types.UNLOCK_TA_STARTED:
      return Object.assign({}, state, {
        docMessage:"UnLocking TA " + payload.ta_number + "..."
        , docMessageSeverity:FEEDBACK_WAITING
      });
      
    case types.UNLOCK_TA_FAILED:
      return Object.assign({}, state, {
        docMessage:payload.message
        , docMessageSeverity:payload.severity
      });
      
    case types.UNLOCK_TA_COMPLETE:
      return Object.assign({}, state, {
        docMessage:"Locked ok."
        , docMessageSeverity:FEEDBACK_MESSAGE
      });
      
    case types.LOADING_TA_LINES_STARTED:
      return Object.assign({}, state, {
        linesMessage:"Loading lines..."
        , linesMessageSeverity:FEEDBACK_WAITING
        , lines:null
      });
      
    case types.LOADING_TA_LINES_FAILED:
      return Object.assign({}, state, {
        linesMessage:payload.message
        , linesMessageSeverity:payload.severity
        , lines:null
      });
      
    case types.LOADING_TA_LINES_COMPLETE:
      return Object.assign({}, state, {
        linesMessage:null
        , linesMessageSeverity:FEEDBACK_MESSAGE
        , lines:payload.lines
      });
      
    case types.LOADING_TA_LINE_STARTED:
      return Object.assign({}, state, {
        lineMessage:"Loading line..."
        , lineMessageSeverity:FEEDBACK_WAITING
        , line:null
      });
      
    case types.LOADING_TA_LINE_FAILED:
      return Object.assign({}, state, {
        lineMessage:payload.message
        , lineMessageSeverity:payload.severity
      });
      
    case types.LOADING_TA_LINE_COMPLETE:
      return Object.assign({}, state, {
        lineMessage:null
        , lineMessageSeverity:null
        , line:payload.line
      });
      
    case types.CLEAR_TA_LINE:
      return Object.assign({}, state, {
        lineMessage:null
        , lineMessageSeverity:null
        , line:null
        , lineSavedOK:null
      });
      
    case types.TA_LINE_SET_PART:
      // adjust line based on part type
      let rotable = payload.part && payload.part.rotable == 1

      let newLineSource = { part: payload.part
        , quantity:null
        , serial_number:null
       }

      if(payload.part){
        newLineSource.unit_price = payload.part.mlp
      }

      if(payload.stockLine){
        newLineSource.quantity = rotable ? "1.00" : payload.stockLine.qty
        newLineSource.condition_id = payload.stockLine.condition_id
        newLineSource.partconditionname = payload.stockLine.partconditionname
        newLineSource.hazardname = payload.stockLine.hazardname
        newLineSource.hazard_id = payload.stockLine.hazard_id
        newLineSource.serial_number = payload.stockLine.serial
        newLineSource.srce_grn_entry_id = payload.stockLine.entry_id
        newLineSource.transaction_id = TRANS_REPAIR
        newLineSource.deal_id = 9 // Repair
        newLineSource.comment = payload.stockLine.comment
        newLineSource.warranty = 0
        newLineSource.on_hold = 0
        newLineSource.expected_costs = "0.00"
        newLineSource.p_for_stock = 0
       }

      return Object.assign({}, state, {
        line: Object.assign({}, state.line, newLineSource)
      })

    case types.NEW_TA_LINE_STARTED:
      return Object.assign({}, state, {
        lineMessage:null
        , lineMessageSeverity:null
        , line:payload
      });
      
      // case types.EDIT_TA_FORM_SUBMIT_STARTED:
      //   return Object.assign({}, state, {
      //       daNewStatus:newStatus(state.da.status, payload.newStatus)
      //     });
      //   
      case types.UPDATE_TA_STARTED:
        return Object.assign({}, state, {
              docMessage:"Saving..."
              , docMessageSeverity:FEEDBACK_WAITING
          });
        
      case types.UPDATE_TA_FAILED:
        return Object.assign({}, state, {
            docMessage:payload.message
            , docMessageSeverity:payload.severity
          });
        
      case types.UPDATE_TA_COMPLETE:
        return Object.assign({}, state, {
            docMessage:null
            , docMessageSeverity:null
          });
        
      case types.SAVE_TA_LINE_STARTED:
        return Object.assign({}, state, {
              lineMessage:"Saving..."
              , lineMessageSeverity:FEEDBACK_WAITING
          });
        
      case types.SAVE_TA_LINE_FAILED:
        return Object.assign({}, state, {
            lineMessage:payload.message
            , lineMessageSeverity:payload.severity
          });
        
      case types.SAVE_TA_LINE_COMPLETE:
        return Object.assign({}, state, {
            lineMessage:"Saved ok"
            , lineMessageSeverity:FEEDBACK_MESSAGE
            , line:null
            , lineSavedOK: true
          });
        
      case types.PRINT_TA_STARTED:
        return Object.assign({}, state, {
          docMessage:"Printing TA " + state.doc.ta_number + "..."
          , docMessageSeverity:FEEDBACK_WAITING
        });
        
      case types.PRINT_TA_FAILED:
        return Object.assign({}, state, {
          docMessage:payload.message
          , docMessageSeverity:payload.severity
        });
        
      case types.PRINT_TA_COMPLETE:
        return Object.assign({}, state, {
          docMessage:"Printed and downloaded ok."
          , docMessageSeverity:FEEDBACK_MESSAGE
        });
        
      case types.LOADING_TA_SUPPLIER_STARTED:
        return Object.assign({}, state, {
          loadingSupplierMessage:"Loading Supplier..."
          , loadingSupplierMessageSeverity:FEEDBACK_WAITING
        });
        
      case types.LOADING_TA_SUPPLIER_FAILED:
        return Object.assign({}, state, {
          loadingSupplierMessage:payload.message
          , loadingSupplierMessageSeverity:payload.severity
        });
        
      case types.LOADING_TA_SUPPLIER_COMPLETE:
        return Object.assign({}, state, {
          loadingSupplierMessage:null
          , loadingSupplierMessageSeverity:null
        });
        
      case types.DELETE_TA_LINE_STARTED:
        return Object.assign({}, state, {
              lineMessage:"Deleting..."
              , lineMessageSeverity:FEEDBACK_WAITING
          });
        
      case types.DELETE_TA_LINE_FAILED:
        return Object.assign({}, state, {
            lineMessage:payload.message
            , lineMessageSeverity:payload.severity
          });
        
      case types.DELETE_TA_LINE_COMPLETE:
        return Object.assign({}, state, {
            lineMessage:null
            , lineMessageSeverity:null
            , line:null
            , lines:null
            , docMessage: "Line deleted"
            , docMessageSeverity: FEEDBACK_MESSAGE
          });
        
      case types.DELETE_TA_STARTED:
        return Object.assign({}, state, {
              docMessage:"Deleting..."
              , docMessageSeverity:FEEDBACK_WAITING
          });
        
      case types.DELETE_TA_FAILED:
        return Object.assign({}, state, {
            docMessage:payload.message
            , docMessageSeverity:payload.severity
          });
        
      case types.DELETE_TA_COMPLETE:
        return Object.assign({}, state, {
            docMessage:null
            , docMessageSeverity:null
            , doc:null
            , message:"TA and lines deleted"
            , messageSeverity:FEEDBACK_MESSAGE
          });
        
      case types.LOADING_TA_DELIVERY_ADDRESS_STARTED:
        return Object.assign({}, state, {
          loadingDeliveryAddressMessage:"Loading..."
          , loadingDeliveryAddressMessageSeverity:FEEDBACK_WAITING
        });
        
      case types.LOADING_TA_DELIVERY_ADDRESS_FAILED:
        return Object.assign({}, state, {
          loadingDeliveryAddressMessage:payload.message
          , loadingDeliveryAddressMessageSeverity:payload.severity
        });
        
      case types.LOADING_TA_DELIVERY_ADDRESS_COMPLETE:
        return Object.assign({}, state, {
          loadingDeliveryAddressMessage:null
          , loadingDeliveryAddressMessageSeverity: null
        });

      case types.LOADING_TA_INVOICE_ADDRESS_STARTED:
        return Object.assign({}, state, {
          loadingInvoiceAddressMessage:"Loading..."
          , loadingInvoiceAddressMessageSeverity:FEEDBACK_WAITING
        });
        
      case types.LOADING_TA_INVOICE_ADDRESS_FAILED:
        return Object.assign({}, state, {
          loadingInvoiceAddressMessage:payload.message
          , loadingInvoiceAddressMessageSeverity:payload.severity
        });
        
      case types.LOADING_TA_INVOICE_ADDRESS_COMPLETE:
        return Object.assign({}, state, {
          loadingInvoiceAddressMessage:null
          , loadingInvoiceAddressMessageSeverity:null
        });
      default:
      return state;
  }
}
