import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import App from '../components/App'

class Root extends Component {
  render = () => (
    <Provider store={this.props.store}>
      <App
        isLoggedIn={this.props.loggedIn}
        mainMenuExpanded={this.props.mainMenuExpanded}
        secondMenuExpanded={this.props.secondMenuExpanded}
        />
    </Provider>
  )
}

const mapStateToProps = state => ({
  loggedIn:state.auth && state.auth.loggedIn
  , mainMenuExpanded:state.menu.mainMenuExpanded
  , secondMenuExpanded:state.menu.secondMenuExpanded
})

export default connect(mapStateToProps)(Root)
