import * as types from '../actions/types'
import {
  FEEDBACK_WAITING,
  FEEDBACK_MESSAGE
} from '../lib/message-types'
import {
  TRANS_REPAIR
} from '../lib/transactions'

const initialState = {}

export default (state = initialState, {
  type,
  payload
}) => {

  switch (type) {

    case types.CLEAR_ALL_DA_MESSAGES:
      return Object.assign({}, state, {
        message: null,
        mesageSeverity: null,
        searchMessage: null,
        searchMesageSeverity: null,
        docMessage: null,
        docMessageSeverity: null
      });

      case types.CREATE_DA_FORM_SUBMITTED:
        return Object.assign({}, state, {
          createMessage:"Creating DA..."
          , createMessageSeverity:FEEDBACK_WAITING
        });

      case types.CREATE_DA_FAILED:
        return Object.assign({}, state, {
          createMessage:payload.message
          , createMessageSeverity:payload.severity
        });

      case types.CREATE_DA_COMPLETE:
        return Object.assign({}, state, {
          createMessage:null
          , createMessageSeverity:null
        });

    case types.DAS_SEARCH_STARTED:
      return Object.assign({}, state, {
        searchMessage: "Searching...",
        searchMessageSeverity: FEEDBACK_WAITING,
        searchResults: null
      });

    case types.DAS_SEARCH_FAILED:
      return Object.assign({}, state, {
        searchMessage: payload.message,
        searchMessageSeverity: payload.severity,
        searchResults: null
      });

    case types.DAS_SEARCH_COMPLETE:
      let sm, sms;
      if (payload.results.length == 0) {
        sm = "No results, try with a wilcard '*' on the end";
        sms = FEEDBACK_MESSAGE;
      }
      return Object.assign({}, state, {
        searchMessage: sm,
        searchMessageSeverity: sms,
        searchResults: payload.results
      });

      // case types.ADD_STOCK_LINE_ADD:
      //   return Object.assign({}, state, {
      //     searchMessage:null
      //     , searchMessageSeverity:null
      //     , searchResults:null
      //   });

    case types.GET_DA_STARTED:
      return Object.assign({}, state, {
        docMessage: "Loading DA " + payload.da_number + "...",
        docMessageSeverity: FEEDBACK_WAITING,
        doc: null,
        lines: null
      });

    case types.GET_DA_FAILED:
      return Object.assign({}, state, {
        docMessage: payload.message,
        docMessageSeverity: payload.severity
      });

      // case types.SET_DA_VALUES:
      //   return Object.assign({}, state, {
      //     doc:Object.assign({}, state.doc, payload.values)
      //   });

    case types.GET_DA_COMPLETE:
      return Object.assign({}, state, {
        docMessage: null,
        docMessageSeverity: null,
        lockMessage: null,
        lockMessageSeverity: null,
        doc: payload.doc,
        lines: null
      });

    case types.LOCK_DA_STARTED:
      return Object.assign({}, state, {
        lockMessage: "Locking DA " + payload.da_number + "...",
        lockMessageSeverity: FEEDBACK_WAITING
      });

    case types.LOCK_DA_FAILED:
      return Object.assign({}, state, {
        lockMessage: payload.message,
        lockMessageSeverity: payload.severity
      });

    case types.LOCK_DA_COMPLETE:
      return Object.assign({}, state, {
        lockMessage: "Locked ok.",
        lockMessageSeverity: FEEDBACK_MESSAGE
      });

    case types.UNLOCK_DA_STARTED:
      return Object.assign({}, state, {
        docMessage: "UnLocking DA " + payload.da_number + "...",
        docMessageSeverity: FEEDBACK_WAITING
      });

    case types.UNLOCK_DA_FAILED:
      return Object.assign({}, state, {
        docMessage: payload.message,
        docMessageSeverity: payload.severity
      });

    case types.UNLOCK_DA_COMPLETE:
      return Object.assign({}, state, {
        docMessage: "Locked ok.",
        docMessageSeverity: FEEDBACK_MESSAGE
      });

    case types.LOADING_DA_LINES_STARTED:
      return Object.assign({}, state, {
        linesMessage: "Loading lines...",
        linesMessageSeverity: FEEDBACK_WAITING,
        lines: null
      });

    case types.LOADING_DA_LINES_FAILED:
      return Object.assign({}, state, {
        linesMessage: payload.message,
        linesMessageSeverity: payload.severity,
        lines: null
      });

    case types.LOADING_DA_LINES_COMPLETE:
      return Object.assign({}, state, {
        linesMessage: null,
        linesMessageSeverity: FEEDBACK_MESSAGE,
        lines: payload
      });

      case types.LOADING_DA_LINE_STARTED:
        return Object.assign({}, state, {
          lineMessage:"Loading line..."
          , lineMessageSeverity:FEEDBACK_WAITING
          , line:null
        });

      case types.LOADING_DA_LINE_FAILED:
        return Object.assign({}, state, {
          lineMessage:payload.message
          , lineMessageSeverity:payload.severity
        });

      case types.LOADING_DA_LINE_COMPLETE:
        return Object.assign({}, state, {
          lineMessage:null
          , lineMessageSeverity:null
          , line:payload.line
        });

      case types.CLEAR_DA_LINE:
        return Object.assign({}, state, {
          lineMessage:null
          , lineMessageSeverity:null
          , line:null
          , lineSavedOK:null
        });
      case types.DA_LINE_SET_ADHOC_LINE_SOURCE:
        let newAdhocSource = {}

        if (payload) {
          newAdhocSource.dainitials = payload.da_inits
          newAdhocSource.danumber = payload.da_number
          newAdhocSource.comment = payload.comment
          newAdhocSource.da_entry_id = payload.entry_id
          newAdhocSource.da_id = payload.da_id
          newAdhocSource.serial_number = payload.serial_number
          newAdhocSource.part_id = payload.part_id 
          newAdhocSource.partnumber = payload.part,
          newAdhocSource.partdescription = payload.partdescription
          // 'inv_sort_date', // linked da date
          // 'da_time', // linked da_time
        } else {
          newAdhocSource.dainitials = ''
          newAdhocSource.danumber = ''
          newAdhocSource.da_entry_id = 0
          newAdhocSource.da_id = 0
          newAdhocSource.serial_number = ''
          newAdhocSource.part_id = 0
          newAdhocSource.partnumber = '',
          newAdhocSource.partdescription = ''
        }

        return Object.assign({}, state, {
          line: Object.assign({}, state.line, newAdhocSource)
        })

      case types.DA_LINE_SET_PART:
        // adjust line based on part type
        let rotable = payload.part && payload.part.rotable == 1

        let newLineSource = { part: payload.part
          , quantity:null
          , serial_number:null
         }

        // if(payload.part){
        //   newLineSource.unit_price = payload.part.mlp
        // }

        if(payload.stockLine){
          newLineSource.srce_grn_entry_id = payload.stockLine.entry_id
          newLineSource.quantity = rotable ? "1.00" : payload.stockLine.qty
          newLineSource.partconditionname = payload.stockLine.partconditionname
          newLineSource.hazardname = payload.stockLine.hazardname
          newLineSource.hazard_id = payload.stockLine.hazard_id
          newLineSource.part_id = payload.stockLine.part_id
          newLineSource.srce_grn = payload.stockLine.grn_number
          newLineSource.srce_grn_id = payload.stockLine.grn_id
          newLineSource.srce_is_ugrn = payload.stockLine.is_ugrn
          newLineSource.srce_owner_id = payload.stockLine.customer_id
          newLineSource.location = payload.stockLine.location || ' '
          newLineSource.serial_number = payload.stockLine.serial || ''
          newLineSource.condition_id = payload.stockLine.condition_id
          newLineSource.comment = newLineSource.comment || payload.stockLine.comment || '',
          newLineSource.original_ta = ('' + payload.stockLine.ta_number + payload.stockLine.ta_initials).replace(/\s+/g,'')
          newLineSource.s_exch_srce_ta_entry = payload.stockLine.ta_entry_id || 0
        } else {
          newLineSource.hazardname = ''
          newLineSource.hazard_id = 0
          newLineSource.part_id = 0
          newLineSource.srce_grn = 0
          newLineSource.srce_grn_entry_id = 0
          newLineSource.srce_grn_id = 0
          newLineSource.srce_is_ugrn = 0
          newLineSource.srce_owner_id = 0
          newLineSource.original_ta = ''
          newLineSource.s_exch_srce_ta_entry = 0
        }

        return Object.assign({}, state, {
          line: Object.assign({}, state.line, newLineSource)
        })

      case types.NEW_DA_LINE_STARTED:
        return Object.assign({}, state, {
          lineMessage:null
          , lineMessageSeverity:null
          , line:payload
        });

      // case types.EDIT_DA_FORM_SUBMIT_STARTED:
      //   return Object.assign({}, state, {
      //       daNewStatus:newStatus(state.da.status, payload.newStatus)
      //     });
      //   
      case types.UPDATE_DA_STARTED:
        return Object.assign({}, state, {
              docMessage:"Saving..."
              , docMessageSeverity:FEEDBACK_WAITING
          });

      case types.UPDATE_DA_FAILED:
        return Object.assign({}, state, {
            docMessage:payload.message
            , docMessageSeverity:payload.severity
          });

      case types.UPDATE_DA_COMPLETE:
        return Object.assign({}, state, {
            docMessage:null
            , docMessageSeverity:null
          });

        case types.SAVE_DA_LINE_STARTED:
          return Object.assign({}, state, {
                lineMessage:"Saving..."
                , lineMessageSeverity:FEEDBACK_WAITING
            });

        case types.SAVE_DA_LINE_FAILED:
          return Object.assign({}, state, {
              lineMessage:payload.message
              , lineMessageSeverity:payload.severity
            });

        case types.SAVE_DA_LINE_COMPLETE:
          return Object.assign({}, state, {
              lineMessage:"Saved ok"
              , lineMessageSeverity:FEEDBACK_MESSAGE
              , line:null
              , lineSavedOK: true
            });

    case types.LOADING_INVOICE_COSTS_STARTED:
      return Object.assign({}, state, {
        invoiceTotalsMessage: "Calculating...",
        invoiceTotalsMessageSeverity: FEEDBACK_WAITING,
        invoiceTotals: null
      });
      break;
    case types.LOADING_INVOICE_COSTS_FAILED:
      return Object.assign({}, state, {
        invoiceTotalsMessage: payload.message,
        invoiceTotalsMessageSeverity: payload.severity
      })
      break;
    case types.LOADING_INVOICE_COSTS_COMPLETE:
      return Object.assign({}, state, {
        invoiceTotalsMessage: null,
        invoiceTotalsMessageSeverity: null,
        invoiceTotals: payload
      })
      break;

    case types.PRINT_DA_STARTED:
      return Object.assign({}, state, {
        docMessage: "Printing DA " + state.doc.da_number + "...",
        docMessageSeverity: FEEDBACK_WAITING
      });

    case types.PRINT_DA_FAILED:
      return Object.assign({}, state, {
        docMessage: payload.message,
        docMessageSeverity: payload.severity
      });

    case types.PRINT_DA_COMPLETE:
      return Object.assign({}, state, {
        docMessage: "Printed and downloaded ok.",
        docMessageSeverity: FEEDBACK_MESSAGE
      });

      //   case types.LOADING_DA_SUPPLIER_STARTED:
      //     return Object.assign({}, state, {
      //       loadingSupplierMessage:"Loading Supplier..."
      //       , loadingSupplierMessageSeverity:FEEDBACK_WAITING
      //     });

      //   case types.LOADING_DA_SUPPLIER_FAILED:
      //     return Object.assign({}, state, {
      //       loadingSupplierMessage:payload.message
      //       , loadingSupplierMessageSeverity:payload.severity
      //     });

      //   case types.LOADING_DA_SUPPLIER_COMPLETE:
      //     return Object.assign({}, state, {
      //       loadingSupplierMessage:null
      //       , loadingSupplierMessageSeverity:null
      //     });

        case types.DELETE_DA_LINE_STARTED:
          return Object.assign({}, state, {
                lineMessage:"Deleting..."
                , lineMessageSeverity:FEEDBACK_WAITING
            });

        case types.DELETE_DA_LINE_FAILED:
          return Object.assign({}, state, {
              lineMessage:payload.message
              , lineMessageSeverity:payload.severity
            });

        case types.DELETE_DA_LINE_COMPLETE:
          return Object.assign({}, state, {
              lineMessage:null
              , lineMessageSeverity:null
              , line:null
              , lines:null
              , docMessage: "Line deleted"
              , docMessageSeverity: FEEDBACK_MESSAGE
            });

        case types.DELETE_DA_STARTED:
          return Object.assign({}, state, {
                docMessage:"Deleting..."
                , docMessageSeverity:FEEDBACK_WAITING
            });

        case types.DELETE_DA_FAILED:
          return Object.assign({}, state, {
              docMessage:payload.message
              , docMessageSeverity:payload.severity
            });

        case types.DELETE_DA_COMPLETE:
          return Object.assign({}, state, {
              docMessage:null
              , docMessageSeverity:null
              , doc:null
              , message:"DA and lines deleted"
              , messageSeverity:FEEDBACK_MESSAGE
            });

        case types.LOADING_DA_DELIVERY_ADDRESS_STARTED:
          return Object.assign({}, state, {
            loadingDeliveryAddressMessage:"Loading..."
            , loadingDeliveryAddressMessageSeverity:FEEDBACK_WAITING
          });

        case types.LOADING_DA_DELIVERY_ADDRESS_FAILED:
          return Object.assign({}, state, {
            loadingDeliveryAddressMessage:payload.message
            , loadingDeliveryAddressMessageSeverity:payload.severity
          });

        case types.LOADING_DA_DELIVERY_ADDRESS_COMPLETE:
          return Object.assign({}, state, {
            loadingDeliveryAddressMessage:null
            , loadingDeliveryAddressMessageSeverity: null
          });

      //   case types.LOADING_DA_INVOICE_ADDRESS_STARTED:
      //     return Object.assign({}, state, {
      //       loadingInvoiceAddressMessage:"Loading..."
      //       , loadingInvoiceAddressMessageSeverity:FEEDBACK_WAITING
      //     });

      //   case types.LOADING_DA_INVOICE_ADDRESS_FAILED:
      //     return Object.assign({}, state, {
      //       loadingInvoiceAddressMessage:payload.message
      //       , loadingInvoiceAddressMessageSeverity:payload.severity
      //     });

      //   case types.LOADING_DA_INVOICE_ADDRESS_COMPLETE:
      //     return Object.assign({}, state, {
      //       loadingInvoiceAddressMessage:null
      //       , loadingInvoiceAddressMessageSeverity:null
      //     });
    default:
      return state;
  }
}