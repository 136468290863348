import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import withRouter from '../lib/withRouter'

class NotFound extends Component {

  render(){
    return (
      <div> {/* wrapper */}

       <div className="banner">
         <div className="container">
           <h1>404 Not found</h1>
           <p>Unrecognised path "{location.pathname}"</p>
         </div>
       </div>

       <div className="container">

         <div className="box">
           <h2>Page not found</h2>
           <p>Sorry, we could not locate page <em>{location.pathname}</em>.</p>
           <p>If you typed this URL in yourself then that is why.</p>
           <p>Otherwise it is likely the item has been deleted.</p>
           <p>From here you can <button onClick={this.props.history.goBack}>go back</button> or <Link className="button" to="/">go to the home page</Link>.</p>
         </div>

       </div>

     {/* end wrapper*/}
     </div>
    )
  }
}

export default withRouter(NotFound);
