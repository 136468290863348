export const RENEW_TOKEN_STARTED = 'RENEW_TOKEN_STARTED'
export const LOGIN_FORM_SUBMITTED = 'LOGIN_FORM_SUBMITTED'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const SET_USER_PROFILE = 'SET_USER_PROFILE'
export const LOGOFF = 'LOGOFF'
export const LOGGED_IN = 'LOGGED_IN'
export const LOAD_LOGIN_HISTORY_STARTED = 'LOAD_LOGIN_HISTORY_STARTED'
export const LOAD_LOGIN_HISTORY_FAILED = 'LOAD_LOGIN_HISTORY_FAILED'
export const LOAD_LOGIN_HISTORY_COMPLETE = 'LOAD_LOGIN_HISTORY_COMPLETE'

export const CHECK_API_STARTED = 'CHECK_API_STARTED'
export const CHECK_API_FAILED = 'CHECK_API_FAILED'
export const CHECK_API_COMPLETE = 'CHECK_API_COMPLETE'

export const SET_MENUS = 'SET_MENUS'
export const MAINMENU_EXPANDED = 'MAINMENU_EXPANDED'
export const SECONDMENU_EXPANDED = 'SECONDMENU_EXPANDED'

export const FETCHING_RECENT_DOCUMENTS = 'FETCHING_RECENT_DOCUMENTS'
export const FETCHING_RECENT_DOCUMENTS_FAILED = 'FETCHING_RECENT_DOCUMENTS_FAILED'
export const RECEIVED_RECENT_DOCUMENTS = 'RECEIVED_RECENT_DOCUMENTS'

export const LOAD_STOCKHISTORY_STARTED = 'LOAD_STOCKHISTORY_STARTED'
export const LOAD_STOCKHISTORY_FAILED = 'LOAD_STOCKHISTORY_FAILED'
export const LOAD_STOCKHISTORY_COMPLETE = 'LOAD_STOCKHISTORY_COMPLETE'
export const LOAD_STOCKHISTORY_PART_STARTED = 'LOAD_STOCKHISTORY_PART_STARTED'
export const LOAD_STOCKHISTORY_PART_FAILED = 'LOAD_STOCKHISTORY_PART_FAILED'
export const LOAD_STOCKHISTORY_PART_COMPLETE = 'LOAD_STOCKHISTORY_PART_COMPLETE'
export const TRY_STOCKHISTORY_MODJUMP_STARTED = 'TRY_STOCKHISTORY_MODJUMP_STARTED'
export const TRY_STOCKHISTORY_MODJUMP_FAILED = 'TRY_STOCKHISTORY_MODJUMP_FAILED'
export const TRY_STOCKHISTORY_MODJUMP_COMPLETE = 'TRY_STOCKHISTORY_MODJUMP_COMPLETE'
export const CLEAR_STOCKHISTORY = 'CLEAR_STOCKHISTORY'
export const SET_STOCKHISTORY_PART = 'SET_STOCKHISTORY_PART'

export const LOAD_STOCKENQUIRY_LINES_STARTED = 'LOAD_STOCKENQUIRY_LINES_STARTED'
export const LOAD_STOCKENQUIRY_LINES_FAILED = 'LOAD_STOCKENQUIRY_LINES_FAILED'
export const LOAD_STOCKENQUIRY_LINES_COMPLETE = 'LOAD_STOCKENQUIRY_LINES_COMPLETE'
export const LOAD_STOCKENQUIRY_PART_STARTED = 'LOAD_STOCKENQUIRY_PART_STARTED'
export const LOAD_STOCKENQUIRY_PART_FAILED = 'LOAD_STOCKENQUIRY_PART_FAILED'
export const LOAD_STOCKENQUIRY_PART_COMPLETE = 'LOAD_STOCKENQUIRY_PART_COMPLETE'
export const LOAD_STOCKENQUIRY_PART_COMMENTS_STARTED = 'LOAD_STOCKENQUIRY_PART_COMMENTS_STARTED'
export const LOAD_STOCKENQUIRY_PART_COMMENTS_FAILED = 'LOAD_STOCKENQUIRY_PART_COMMENTS_FAILED'
export const LOAD_STOCKENQUIRY_PART_COMMENTS_COMPLETE = 'LOAD_STOCKENQUIRY_PART_COMMENTS_COMPLETE'
export const LOAD_STOCKENQUIRY_ALTS_STARTED = 'LOAD_STOCKENQUIRY_ALTS_STARTED'
export const LOAD_STOCKENQUIRY_ALTS_FAILED = 'LOAD_STOCKENQUIRY_ALTS_FAILED'
export const LOAD_STOCKENQUIRY_ALTS_COMPLETE = 'LOAD_STOCKENQUIRY_ALTS_COMPLETE'
export const LOAD_STOCKENQUIRY_OSPR_STARTED = 'LOAD_STOCKENQUIRY_OSPR_STARTED'
export const LOAD_STOCKENQUIRY_OSPR_FAILED = 'LOAD_STOCKENQUIRY_OSPR_FAILED'
export const LOAD_STOCKENQUIRY_OSPR_COMPLETE = 'LOAD_STOCKENQUIRY_OSPR_COMPLETE'
export const LOAD_STOCKENQUIRY_OSEX_STARTED = 'LOAD_STOCKENQUIRY_OSEX_STARTED'
export const LOAD_STOCKENQUIRY_OSEX_FAILED = 'LOAD_STOCKENQUIRY_OSEX_FAILED'
export const LOAD_STOCKENQUIRY_OSEX_COMPLETE = 'LOAD_STOCKENQUIRY_OSEX_COMPLETE'
export const LOAD_STOCKENQUIRY_QUOTES_STARTED = 'LOAD_STOCKENQUIRY_QUOTES_STARTED'
export const LOAD_STOCKENQUIRY_QUOTES_FAILED = 'LOAD_STOCKENQUIRY_QUOTES_FAILED'
export const LOAD_STOCKENQUIRY_QUOTES_COMPLETE = 'LOAD_STOCKENQUIRY_QUOTES_COMPLETE'

export const CLEAR_STOCKENQUIRY = 'CLEAR_STOCKENQUIRY'
export const SET_STOCKENQUIRY_PART = 'SET_STOCKENQUIRY_PART'

export const DAS_SEARCH_STARTED = 'DAS_SEARCH_STARTED'
export const DAS_SEARCH_FAILED = 'DAS_SEARCH_FAILED'
export const DAS_SEARCH_COMPLETE = 'DAS_SEARCH_COMPLETE'

export const CREATE_DA_FORM_SUBMITTED = 'CREATE_DA_FORM_SUBMITTED'
export const CREATE_DA_FAILED = 'CREATE_DA_FAILED'
export const CREATE_DA_COMPLETE = 'CREATE_DA_COMPLETE'

export const LOADING_INVOICE_COSTS_STARTED = 'LOADING_INVOICE_COSTS_STARTED'
export const LOADING_INVOICE_COSTS_FAILED = 'LOADING_INVOICE_COSTS_FAILED'
export const LOADING_INVOICE_COSTS_COMPLETE = 'LOADING_INVOICE_COSTS_COMPLETE'

export const EDIT_DA_FORM_SUBMIT_STARTED = 'EDIT_DA_FORM_SUBMIT_STARTED'
// export const EDIT_DA_FORM_FLAGS_UPDATED = 'EDIT_DA_FORM_FLAGS_UPDATED'
export const UPDATE_DA_STARTED = 'UPDATE_DA_STARTED'
export const UPDATE_DA_FAILED = 'UPDATE_DA_FAILED'
export const UPDATE_DA_COMPLETE = 'UPDATE_DA_COMPLETE'
export const SET_DA_VALUES = 'SET_DA_VALUES'

export const SAVE_DA_LINE_STARTED = 'SAVE_DA_LINE_STARTED'
export const SAVE_DA_LINE_FAILED = 'SAVE_DA_LINE_FAILED'
export const SAVE_DA_LINE_COMPLETE = 'SAVE_DA_LINE_COMPLETE'

export const LOG_INVOICE_STARTED = 'LOG_INVOICE_FORM_SUBMITTED'
export const LOG_INVOICE_FAILED = 'LOG_INVOICE_FAILED'
export const LOG_INVOICE_COMPLETE = 'LOG_INVOICE_COMPLETE'

export const GET_DA_STARTED = 'GET_DA_STARTED'
export const GET_DA_FAILED = 'GET_DA_FAILED'
export const GET_DA_COMPLETE = 'GET_DA_COMPLETE'

export const LOCK_DA_STARTED = 'LOCK_DA_STARTED'
export const LOCK_DA_FAILED = 'LOCK_DA_FAILED'
export const LOCK_DA_COMPLETE = 'LOCK_DA_COMPLETE'

export const UNLOCK_DA_STARTED = 'UNLOCK_DA_STARTED'
export const UNLOCK_DA_FAILED = 'UNLOCK_DA_FAILED'
export const UNLOCK_DA_COMPLETE = 'UNLOCK_DA_COMPLETE'

export const LOADING_DA_LINES_STARTED = 'LOADING_DA_LINES_STARTED'
export const LOADING_DA_LINES_FAILED = 'LOADING_DA_LINES_FAILED'
export const LOADING_DA_LINES_COMPLETE = 'LOADING_DA_LINES_COMPLETE'

export const LOADING_DA_LINE_STARTED = 'LOADING_DA_LINE_STARTED'
export const LOADING_DA_LINE_FAILED = 'LOADING_DA_LINE_FAILED'
export const LOADING_DA_LINE_COMPLETE = 'LOADING_DA_LINE_COMPLETE'
export const CLEAR_DA_LINE = 'CLEAR_DA_LINE'

export const NEW_DA_LINE_STARTED = 'NEW_DA_LINE_STARTED'

export const DA_LINE_SET_PART = 'DA_LINE_SET_PART'

export const DA_SOURCE_GRN_LINE_LINKED = 'DA_SOURCE_GRN_LINE_LINKED'
export const DA_SOURCE_GRN_LINE_UNLINKED = 'DA_SOURCE_GRN_LINE_UNLINKED'

export const LOADING_UNLOGGED_DAS = 'LOADING_UNLOGGED_DAS'
export const LOADING_UNLOGGED_DAS_FAILED = 'LOADING_UNLOGGED_DAS_FAILED' //api says no
export const LOADING_UNLOGGED_DAS_COMPLETE = 'LOADING_UNLOGGED_DAS_COMPLETE'

// we are going to try for dynamic item types
export const LOADING_ITEMS_STARTED = 'LOADING_ITEMS_STARTED'
export const LOADING_ITEMS_FAILED = 'LOADING_ITEMS_FAILED' //api says no
export const LOADING_ITEMS_COMPLETE = 'LOADING_ITEMS_COMPLETE'
export const LOADING_ITEMS_NOT_REQUIRED = 'LOADING_ITEMS_COMPLETE_NOT_REQUIRED' // cache up to date

export const LOOKUP_SOURCE_GRN_LINES_STARTED = 'LOOKUP_SOURCE_GRN_LINES_STARTED'
export const LOOKUP_SOURCE_GRN_LINES_FAILED = 'LOOKUP_SOURCE_GRN_LINES_FAILED'
export const LOOKUP_SOURCE_GRN_LINES_COMPLETE = 'LOOKUP_SOURCE_GRN_LINES_COMPLETE'
export const LOOKUP_SOURCE_GRN_LINES_CLEAR = 'LOOKUP_SOURCE_GRN_LINES_CLEAR'

export const LOOKUP_SOURCE_DA_LINES_STARTED = 'LOOKUP_SOURCE_DA_LINES_STARTED'
export const LOOKUP_SOURCE_DA_LINES_FAILED = 'LOOKUP_SOURCE_DA_LINES_FAILED'
export const LOOKUP_SOURCE_DA_LINES_COMPLETE = 'LOOKUP_SOURCE_DA_LINES_COMPLETE'
export const LOOKUP_SOURCE_DA_LINES_CLEAR = 'LOOKUP_SOURCE_DA_LINES_CLEAR'

export const LOOKUP_SOURCE_TA_LINES_STARTED = 'LOOKUP_SOURCE_TA_LINES_STARTED'
export const LOOKUP_SOURCE_TA_LINES_FAILED = 'LOOKUP_SOURCE_TA_LINES_FAILED'
export const LOOKUP_SOURCE_TA_LINES_COMPLETE = 'LOOKUP_SOURCE_TA_LINES_COMPLETE'
export const LOOKUP_SOURCE_TA_LINES_CLEAR = 'LOOKUP_SOURCE_TA_LINES_CLEAR'

export const LOOKUP_PARTS_STARTED = 'LOOKUP_PARTS_STARTED'
export const LOOKUP_PARTS_FAILED = 'LOOKUP_PARTS_FAILED'
export const LOOKUP_PARTS_COMPLETE = 'LOOKUP_PARTS_COMPLETE'

export const LOOKUP_PARTS_CLEAR = 'LOOKUP_PARTS_CLEAR'
export const LOOKUP_PART_CLEAR = 'LOOKUP_PART_CLEAR'

export const LOAD_LOOKUP_PART_STARTED = 'LOAD_LOOKUP_PART_STARTED'
export const LOAD_LOOKUP_PART_FAILED = 'LOAD_LOOKUP_PART_FAILED'
export const LOAD_LOOKUP_PART_COMPLETE = 'LOAD_LOOKUP_PART_COMPLETE'

export const CREATE_PART_STARTED = 'CREATE_PART_STARTED'
export const CREATE_PART_FAILED = 'CREATE_PART_FAILED'
export const CREATE_PART_COMPLETE = 'CREATE_PART_COMPLETE'

export const LOOKUP_USERS_STARTED = 'LOOKUP_USERS_STARTED'
export const LOOKUP_USERS_FAILED = 'LOOKUP_USERS_FAILED'
export const LOOKUP_USERS_COMPLETE = 'LOOKUP_USERS_COMPLETE'
export const LOOKUP_USERS_CLEAR = 'LOOKUP_USERS_CLEAR'

export const LOAD_USER_STARTED = 'LOAD_USER_STARTED'
export const LOAD_USER_FAILED = 'LOAD_USER_FAILED'
export const LOAD_USER_COMPLETE = 'LOAD_USER_COMPLETE'

export const UPDATE_USER_STARTED = 'UPDATE_USER_STARTED'
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED'
export const UPDATE_USER_COMPLETE = 'UPDATE_USER_COMPLETE'

export const ADD_STOCK_LINE_ADD = 'ADD_STOCK_LINE_ADD'
export const ADD_STOCK_LINE_CLEAR = 'ADD_STOCK_LINE_CLEAR'

export const QUOTES_SEARCH_STARTED = 'QUOTES_SEARCH_STARTED'
export const QUOTES_SEARCH_FAILED = 'QUOTES_SEARCH_FAILED'
export const QUOTES_SEARCH_COMPLETE = 'QUOTES_SEARCH_COMPLETE'

export const CREATE_QUOTE_FORM_SUBMITTED = 'CREATE_QUOTE_FORM_SUBMITTED'
export const CREATE_QUOTE_FAILED = 'CREATE_QUOTE_FAILED'
export const CREATE_QUOTE_COMPLETE = 'CREATE_QUOTE_COMPLETE'

export const EDIT_QUOTE_FORM_SUBMIT_STARTED = 'EDIT_QUOTE_FORM_SUBMIT_STARTED'
export const UPDATE_QUOTE_STARTED = 'UPDATE_QUOTE_STARTED'
export const UPDATE_QUOTE_FAILED = 'UPDATE_QUOTE_FAILED'
export const UPDATE_QUOTE_COMPLETE = 'UPDATE_QUOTE_COMPLETE'
export const SET_QUOTE_VALUES = 'SET_QUOTE_VALUES'

export const SAVE_QUOTE_LINE_STARTED = 'SAVE_QUOTE_LINE_STARTED'
export const SAVE_QUOTE_LINE_FAILED = 'SAVE_QUOTE_LINE_FAILED'
export const SAVE_QUOTE_LINE_COMPLETE = 'SAVE_QUOTE_LINE_COMPLETE'

export const GET_QUOTE_STARTED = 'GET_QUOTE_STARTED'
export const GET_QUOTE_FAILED = 'GET_QUOTE_FAILED'
export const GET_QUOTE_COMPLETE = 'GET_QUOTE_COMPLETE'

export const LOCK_QUOTE_STARTED = 'LOCK_QUOTE_STARTED'
export const LOCK_QUOTE_FAILED = 'LOCK_QUOTE_FAILED'
export const LOCK_QUOTE_COMPLETE = 'LOCK_QUOTE_COMPLETE'

export const UNLOCK_QUOTE_STARTED = 'UNLOCK_QUOTE_STARTED'
export const UNLOCK_QUOTE_FAILED = 'UNLOCK_QUOTE_FAILED'
export const UNLOCK_QUOTE_COMPLETE = 'UNLOCK_QUOTE_COMPLETE'

export const LOADING_QUOTE_LINES_STARTED = 'LOADING_QUOTE_LINES_STARTED'
export const LOADING_QUOTE_LINES_FAILED = 'LOADING_QUOTE_LINES_FAILED'
export const LOADING_QUOTE_LINES_COMPLETE = 'LOADING_QUOTE_LINES_COMPLETE'

export const LOADING_QUOTE_LINE_STARTED = 'LOADING_QUOTE_LINE_STARTED'
export const LOADING_QUOTE_LINE_FAILED = 'LOADING_QUOTE_LINE_FAILED'
export const LOADING_QUOTE_LINE_COMPLETE = 'LOADING_QUOTE_LINE_COMPLETE'

export const CALC_QUOTE_VAT_STARTED = 'CALC_QUOTE_VAT_STARTED'
export const CALC_QUOTE_VAT_FAILED = 'CALC_QUOTE_VAT_FAILED'
export const CALC_QUOTE_VAT_COMPLETE = 'CALC_QUOTE_VAT_COMPLETE'

export const QUOTE_SOURCE_GRN_LINE_UNLINKED = 'QUOTE_SOURCE_GRN_LINE_UNLINKED'
export const QUOTE_SOURCE_GRN_LINE_LINKED = 'QUOTE_SOURCE_GRN_LINE_LINKED'

export const CLEAR_QUOTE_LINE = 'CLEAR_QUOTE_LINE'
export const NEW_QUOTE_LINE_STARTED = 'NEW_QUOTE_LINE_STARTED'
export const QUOTE_LINE_SET_PART = 'QUOTE_LINE_SET_PART'

export const PRINT_QUOTE_STARTED = 'PRINT_QUOTE_STARTED'
export const PRINT_QUOTE_FAILED = 'PRINT_QUOTE_FAILED'
export const PRINT_QUOTE_COMPLETE = 'PRINT_QUOTE_COMPLETE'

export const LOADING_QUOTE_CUSTOMER_STARTED = 'LOADING_QUOTE_CUSTOMER_STARTED'
export const LOADING_QUOTE_CUSTOMER_FAILED = 'LOADING_QUOTE_CUSTOMER_FAILED'
export const LOADING_QUOTE_CUSTOMER_COMPLETE = 'LOADING_QUOTE_CUSTOMER_COMPLETE'

export const DELETE_QUOTE_STARTED = 'DELETE_QUOTE_STARTED'
export const DELETE_QUOTE_FAILED = 'DELETE_QUOTE_FAILED'
export const DELETE_QUOTE_COMPLETE = 'DELETE_QUOTE_COMPLETE'

export const DELETE_QUOTE_LINE_STARTED = 'DELETE_QUOTE_LINE_STARTED'
export const DELETE_QUOTE_LINE_FAILED = 'DELETE_QUOTE_LINE_FAILED'
export const DELETE_QUOTE_LINE_COMPLETE = 'DELETE_QUOTE_LINE_COMPLETE'

export const GET_PART_STARTED = 'GET_PART_STARTED'
export const GET_PART_FAILED = 'GET_PART_FAILED'
export const SET_PART = 'SET_PART'
export const CLEAR_PART = 'CLEAR_PART'

export const DELETE_PART_STARTED = 'DELETE_PART_STARTED'
export const DELETE_PART_FAILED = 'DELETE_PART_FAILED'
export const DELETE_PART_COMPLETE = 'DELETE_PART_COMPLETE'
export const DELETE_PART_CLEAR = 'DELETE_PART_CLEAR'

////
export const TAS_SEARCH_STARTED = 'TAS_SEARCH_STARTED'
export const TAS_SEARCH_FAILED = 'TAS_SEARCH_FAILED'
export const TAS_SEARCH_COMPLETE = 'TAS_SEARCH_COMPLETE'

export const CREATE_TA_FORM_SUBMITTED = 'CREATE_TA_FORM_SUBMITTED'
export const CREATE_TA_FAILED = 'CREATE_TA_FAILED'
export const CREATE_TA_COMPLETE = 'CREATE_TA_COMPLETE'

export const EDIT_TA_FORM_SUBMIT_STARTED = 'EDIT_TA_FORM_SUBMIT_STARTED'
// export const EDIT_TA_FORM_FLAGS_UPDATED = 'EDIT_TA_FORM_FLAGS_UPDATED'
export const UPDATE_TA_STARTED = 'UPDATE_TA_STARTED'
export const UPDATE_TA_FAILED = 'UPDATE_TA_FAILED'
export const UPDATE_TA_COMPLETE = 'UPDATE_TA_COMPLETE'
export const SET_TA_VALUES = 'SET_TA_VALUES'

export const SAVE_TA_LINE_STARTED = 'SAVE_TA_LINE_STARTED'
export const SAVE_TA_LINE_FAILED = 'SAVE_TA_LINE_FAILED'
export const SAVE_TA_LINE_COMPLETE = 'SAVE_TA_LINE_COMPLETE'

export const GET_TA_STARTED = 'GET_TA_STARTED'
export const GET_TA_FAILED = 'GET_TA_FAILED'
export const GET_TA_COMPLETE = 'GET_TA_COMPLETE'

export const LOCK_TA_STARTED = 'LOCK_TA_STARTED'
export const LOCK_TA_FAILED = 'LOCK_TA_FAILED'
export const LOCK_TA_COMPLETE = 'LOCK_TA_COMPLETE'

export const UNLOCK_TA_STARTED = 'UNLOCK_TA_STARTED'
export const UNLOCK_TA_FAILED = 'UNLOCK_TA_FAILED'
export const UNLOCK_TA_COMPLETE = 'UNLOCK_TA_COMPLETE'

export const LOADING_TA_LINES_STARTED = 'LOADING_TA_LINES_STARTED'
export const LOADING_TA_LINES_FAILED = 'LOADING_TA_LINES_FAILED'
export const LOADING_TA_LINES_COMPLETE = 'LOADING_TA_LINES_COMPLETE'

export const LOADING_TA_LINE_STARTED = 'LOADING_TA_LINE_STARTED'
export const LOADING_TA_LINE_FAILED = 'LOADING_TA_LINE_FAILED'
export const LOADING_TA_LINE_COMPLETE = 'LOADING_TA_LINE_COMPLETE'

export const TA_SOURCE_GRN_LINE_UNLINKED = 'TA_SOURCE_GRN_LINE_UNLINKED'
export const TA_SOURCE_GRN_LINE_LINKED = 'TA_SOURCE_GRN_LINE_LINKED'

export const CLEAR_TA_LINE = 'CLEAR_TA_LINE'
export const NEW_TA_LINE_STARTED = 'NEW_TA_LINE_STARTED'
export const TA_LINE_SET_PART = 'TA_LINE_SET_PART'

export const PRINT_TA_STARTED = 'PRINT_TA_STARTED'
export const PRINT_TA_FAILED = 'PRINT_TA_FAILED'
export const PRINT_TA_COMPLETE = 'PRINT_TA_COMPLETE'

export const LOADING_TA_SUPPLIER_STARTED = 'LOADING_TA_SUPPLIER_STARTED'
export const LOADING_TA_SUPPLIER_FAILED = 'LOADING_TA_SUPPLIER_FAILED'
export const LOADING_TA_SUPPLIER_COMPLETE = 'LOADING_TA_SUPPLIER_COMPLETE'

export const LOADING_TA_INVOICE_ADDRESS_STARTED = 'LOADING_TA_INVOICE_ADDRESS_STARTED'
export const LOADING_TA_INVOICE_ADDRESS_FAILED = 'LOADING_TA_INVOICE_ADDRESS_FAILED'
export const LOADING_TA_INVOICE_ADDRESS_COMPLETE = 'LOADING_TA_INVOICE_ADDRESS_COMPLETE'

export const LOADING_TA_DELIVERY_ADDRESS_STARTED = 'LOADING_TA_DELIVERY_ADDRESS_STARTED'
export const LOADING_TA_DELIVERY_ADDRESS_FAILED = 'LOADING_TA_DELIVERY_ADDRESS_FAILED'
export const LOADING_TA_DELIVERY_ADDRESS_COMPLETE = 'LOADING_TA_DELIVERY_ADDRESS_COMPLETE'

export const DELETE_TA_STARTED = 'DELETE_TA_STARTED'
export const DELETE_TA_FAILED = 'DELETE_TA_FAILED'
export const DELETE_TA_COMPLETE = 'DELETE_TA_COMPLETE'

export const DELETE_TA_LINE_STARTED = 'DELETE_TA_LINE_STARTED'
export const DELETE_TA_LINE_FAILED = 'DELETE_TA_LINE_FAILED'
export const DELETE_TA_LINE_COMPLETE = 'DELETE_TA_LINE_COMPLETE'

export const LOADING_TA_COMMENTS_STARTED = 'LOADING_TA_COMMENTS_STARTED'
export const LOADING_TA_COMMENTS_FAILED = 'LOADING_TA_COMMENTS_FAILED'
export const LOADING_TA_COMMENTS_COMPLETE = 'LOADING_TA_COMMENTS_COMPLETE'

export const DESTROY_TA_COMMENT = 'DESTROY_TA_COMMENT'
export const SET_TA_COMMENTS_DIRTY = 'SET_TA_COMMENTS_DIRTY'

export const SAVING_TA_COMMENTS_STARTED = 'SAVING_TA_COMMENTS_STARTED'
export const SAVING_TA_COMMENTS_FAILED = 'SAVING_TA_COMMENTS_FAILED'
export const SAVING_TA_COMMENTS_COMPLETE = 'SAVING_TA_COMMENTS_COMPLETE'

export const SAVE_NO_QUOTE_STARTED = 'SAVE_NO_QUOTE_STARTED'
export const SAVE_NO_QUOTE_FAILED = 'SAVE_NO_QUOTE_FAILED'
export const SAVE_NO_QUOTE_COMPLETE = 'SAVE_NO_QUOTE_COMPLETE'

export const GET_NO_QUOTE_STARTED = 'GET_NO_QUOTE_STARTED'
export const GET_NO_QUOTE_FAILED = 'GET_NO_QUOTE_FAILED'
export const GET_NO_QUOTE_COMPLETE = 'GET_NO_QUOTE_COMPLETE'

export const GET_NO_QUOTES_STARTED = 'GET_NO_QUOTES_STARTED'
export const GET_NO_QUOTES_FAILED = 'GET_NO_QUOTES_FAILED'
export const GET_NO_QUOTES_COMPLETE = 'GET_NO_QUOTES_COMPLETE'

export const DELETE_NO_QUOTE_STARTED = 'DELETE_NO_QUOTE_STARTED'
export const DELETE_NO_QUOTE_FAILED = 'DELETE_NO_QUOTE_FAILED'
export const DELETE_NO_QUOTE_COMPLETE = 'DELETE_NO_QUOTE_COMPLETE'

export const GET_NO_QUOTE_REASONS_STARTED = 'GET_NO_QUOTE_REASONS_STARTED'
export const GET_NO_QUOTE_REASONS_FAILED = 'GET_NO_QUOTE_REASONS_FAILED'
export const GET_NO_QUOTE_REASONS_COMPLETE = 'GET_NO_QUOTE_REASONS_COMPLETE'

export const CLEAR_NO_QUOTE_MESSAGES = 'CLEAR_NO_QUOTE_MESSAGES'

export const GET_CONNECTIONS_STARTED = 'GET_CONNECTIONS_STARTED'
export const GET_CONNECTIONS_FAILED = 'GET_CONNECTIONS_FAILED'
export const GET_CONNECTIONS_COMPLETE = 'GET_CONNECTIONS_COMPLETE'

export const CLEAR_ALL_QUOTE_MESSAGES = 'CLEAR_ALL_QUOTE_MESSAGES'

export const LOADING_DA_DELIVERY_ADDRESS_STARTED = 'LOADING_DA_DELIVERY_ADDRESS_STARTED'
export const LOADING_DA_DELIVERY_ADDRESS_FAILED = 'LOADING_DA_DELIVERY_ADDRESS_FAILED'
export const LOADING_DA_DELIVERY_ADDRESS_COMPLETE = 'LOADING_DA_DELIVERY_ADDRESS_COMPLETE'

export const PRINT_DA_STARTED = 'PRINT_DA_STARTED'
export const PRINT_DA_FAILED = 'PRINT_DA_FAILED'
export const PRINT_DA_COMPLETE = 'PRINT_DA_COMPLETE'
export const CLEAR_ALL_DA_MESSAGES = 'CLEAR_ALL_DA_MESSAGES'

export const DELETE_DA_STARTED = 'DELETE_DA_STARTED'
export const DELETE_DA_FAILED = 'DELETE_DA_FAILED'
export const DELETE_DA_COMPLETE = 'DELETE_DA_COMPLETE'

export const DELETE_DA_LINE_STARTED = 'DELETE_DA_LINE_STARTED'
export const DELETE_DA_LINE_FAILED = 'DELETE_DA_LINE_FAILED'
export const DELETE_DA_LINE_COMPLETE = 'DELETE_DA_LINE_COMPLETE'

export const FORM_CHANGED = 'FORM_CHANGED'
export const FORM_RESET = 'FORM_RESET'

export const DA_LINE_SET_ADHOC_LINE_SOURCE = 'DA_LINE_SET_ADHOC_LINE_SOURCE'
