import React  from 'react';
import { Link } from 'react-router-dom';
import NavItem from './NavItem';

const MainNav = ({activeItem, menuItems, onExpanderClick, menuItemClicked}) => (
   <div className="main-nav">

    <div className="container clear">

      <div className="watermark-wrapper">
        <Link className="watermark" to="/"/>
      </div>

      <nav className={ "navbar clear"}>
        <div className="menu-button-wrapper clear">
          <button className="menu-button menu-expander" onClick={onExpanderClick}></button>
        </div>
        <ul className="nav">
          {
            menuItems.map((i) => {
              if(!i.key){
                i.key = "mm-" + i.to;
              }
              return NavItem(i, menuItemClicked, activeItem);
            })
          }
        </ul>
      </nav>

    </div>

  </div>
)

export default MainNav;
