import * as types from '../actions/types'
import * as formNames from '../lib/form-names'
const init = {}
Object.keys(formNames).forEach(form => {
  init[form] = {}
})

export default (state = init, { type, payload }) => {
  switch (type) {
    case types.FORM_CHANGED:
      const form = payload.form
      delete payload.form
      return Object.assign({}, state,
        { [form]: { ...payload } });
    case types.FORM_RESET:
      return Object.assign({}, state,
        {
          [payload]: {
            // dirty: false,
            // submitting: false,
            // hasValidationErrors: false,
            // error: false
          }
        });
    default:
      return state;
  }
}
