import * as types from '../actions/types'
import {FEEDBACK_WAITING, FEEDBACK_MESSAGE} from '../lib/message-types'
import { TRANS_SALE } from '../lib/transactions'

const initialState = {
  // searchMessage : null
  // , searchSeverity : null
  // , searchResults : null
  // , createMessage: null
  // , createMessageSeverity: null
  // , docMessage:null
  // , docMessageSeverity: null
  // , doc: null
  // , lines:{}
  // , linesMessage:null
  // , linesMessageSeverity:null
  // , linesLoaded:null
  // , line:null
  // , lineMessage:null
  // , lineMessageSeverity:null
  // , lookupSourceGRNLine:null
}

export default (state = initialState, {type, payload}) => {

  switch (type) {
      
    case types.CREATE_QUOTE_FORM_SUBMITTED:
      return Object.assign({}, state, {
        createMessage:"Creating Quote..."
        , createMessageSeverity:FEEDBACK_WAITING
      });
      
    case types.CREATE_QUOTE_FAILED:
      return Object.assign({}, state, {
        createMessage:payload.message
        , createMessageSeverity:payload.severity
      });
     
    case types.CREATE_QUOTE_COMPLETE:
      return Object.assign({}, state, {
        createMessage:null
        , createMessageSeverity:null
      });
      
    case types.QUOTES_SEARCH_STARTED:
      return Object.assign({}, state, {
        searchMessage:"Searching..."
        , searchMessageSeverity:FEEDBACK_WAITING
      });
      
    case types.QUOTES_SEARCH_FAILED:
      return Object.assign({}, state, {
        searchMessage:payload.message
        , searchMessageSeverity:payload.severity
      });
      
    case types.QUOTES_SEARCH_COMPLETE:
      let sm, sms;
      if(payload.results.length==0){
        sm = "No results, try with a wilcard '*' on the end";
        sms = FEEDBACK_MESSAGE;
      }
      return Object.assign({}, state, {
        searchMessage:sm
        , searchMessageSeverity:sms
        , searchResults:payload.results
      });

    case types.ADD_STOCK_LINE_ADD:
      return Object.assign({}, state, {
        searchMessage:null
        , searchMessageSeverity:null
        , searchResults:null
      });
  
    case types.GET_QUOTE_STARTED:
      return Object.assign({}, state, {
        docMessage:"Loading Quote " + payload.quote_number + "..."
        , docMessageSeverity:FEEDBACK_WAITING
        , doc:null
        , lines: null
      });
      
    case types.GET_QUOTE_FAILED:
      return Object.assign({}, state, {
        docMessage:payload.message
        , docMessageSeverity:payload.severity
      });
      
    case types.SET_QUOTE_VALUES:
      return Object.assign({}, state, {
        doc:Object.assign({}, state.doc, payload.values)
      });
      
    case types.GET_QUOTE_COMPLETE:
      return Object.assign({}, state, {
        docMessage:null
        , docMessageSeverity:null
        , lockMessage:null
        , lockMessageSeverity:null
        , doc:payload.doc
        , lines: null
      });
      
    case types.LOCK_QUOTE_STARTED:
      return Object.assign({}, state, {
        lockMessage:"Locking Quote " + payload.quote_number + "..."
        , lockMessageSeverity:FEEDBACK_WAITING
      });
      
    case types.LOCK_QUOTE_FAILED:
      return Object.assign({}, state, {
        lockMessage:payload.message
        , lockMessageSeverity:payload.severity
      });
      
    case types.LOCK_QUOTE_COMPLETE:
      return Object.assign({}, state, {
        lockMessage:"Locked ok."
        , lockMessageSeverity:FEEDBACK_MESSAGE
      });
      
    case types.UNLOCK_QUOTE_STARTED:
      return Object.assign({}, state, {
        docMessage:"UnLocking Quote " + payload.quote_number + "..."
        , docMessageSeverity:FEEDBACK_WAITING
      });
      
    case types.UNLOCK_QUOTE_FAILED:
      return Object.assign({}, state, {
        docMessage:payload.message
        , docMessageSeverity:payload.severity
      });
     
    case types.UNLOCK_QUOTE_COMPLETE:
      return Object.assign({}, state, {
        docMessage:"Locked ok."
        , docMessageSeverity:FEEDBACK_MESSAGE
      });
      
    case types.LOADING_QUOTE_LINES_STARTED:
      return Object.assign({}, state, {
        linesMessage:"Loading lines..."
        , linesMessageSeverity:FEEDBACK_WAITING
        , lines:null
      });
      
    case types.LOADING_QUOTE_LINES_FAILED:
      return Object.assign({}, state, {
        linesMessage:payload.message
        , linesMessageSeverity:payload.severity
      });
      
    case types.LOADING_QUOTE_LINES_COMPLETE:
      return Object.assign({}, state, {
        linesMessage:null
        , linesMessageSeverity:FEEDBACK_MESSAGE
        , lines:payload.lines
      });
      
    case types.LOADING_QUOTE_LINE_STARTED:
      return Object.assign({}, state, {
        lineMessage:"Loading line..."
        , lineMessageSeverity:FEEDBACK_WAITING
        , line:null
      });
      
    case types.LOADING_QUOTE_LINE_FAILED:
      return Object.assign({}, state, {
        lineMessage:payload.message
        , lineMessageSeverity:payload.severity
      });
      
    case types.LOADING_QUOTE_LINE_COMPLETE:
      return Object.assign({}, state, {
        lineMessage:null
        , lineMessageSeverity:null
        , line:payload.line
      });
      
    case types.CLEAR_QUOTE_LINE:
      return Object.assign({}, state, {
        lineMessage:null
        , lineMessageSeverity:null
        , line:null
        , lineSavedOK:null
      });
      
    case types.QUOTE_LINE_SET_PART:
      // adjust line based on part type
      let rotable = payload.part && payload.part.rotable == 1

      let newLineSource = { part: payload.part
        , quantity:null
        , serial_number:null
       }

      if(payload.part){
        newLineSource.unit_cost = payload.part.mlp
      }

      if(payload.stockLine){
        newLineSource.quantity = rotable ? "1.00" : payload.stockLine.qty
        newLineSource.condition_id = payload.stockLine.condition_id
        newLineSource.serial_number = payload.stockLine.serial
        newLineSource.cert_date = payload.stockLine.grn_tag_date
        newLineSource.cert = payload.stockLine.cert
      }

      return Object.assign({}, state, {
        line: Object.assign({}, state.line, newLineSource)
      })

    case types.NEW_QUOTE_LINE_STARTED:
      return Object.assign({}, state, {
        lineMessage:null
        , lineMessageSeverity:null
        , line:{
          quote_id:state.doc.quote_id
          , transaction_id:TRANS_SALE}
      });
      
      // case types.EDIT_QUOTE_FORM_SUBMIT_STARTED:
      //   return Object.assign({}, state, {
      //       daNewStatus:newStatus(state.da.status, payload.newStatus)
      //     });
      //   
      case types.UPDATE_QUOTE_STARTED:
        return Object.assign({}, state, {
              docMessage:"Saving..."
              , docMessageSeverity:FEEDBACK_WAITING
          });
        
      case types.UPDATE_QUOTE_FAILED:
        return Object.assign({}, state, {
            docMessage:payload.message
            , docMessageSeverity:payload.severity
          });
        
      case types.UPDATE_QUOTE_COMPLETE:
        return Object.assign({}, state, {
            docMessage:null
            , docMessageSeverity:null
          });
        
      case types.CALC_QUOTE_VAT_STARTED:
        return Object.assign({}, state, {
          vatMessage: "Calculating VAT..."
          , vatMessageSeverity: null
          });
        
      case types.CALC_QUOTE_VAT_FAILED:
        return Object.assign({}, state, {
          vatMessage: payload.message
          , vatMessageSeverity:payload.severity
          })
        
      case types.CALC_QUOTE_VAT_COMPLETE:
        return Object.assign({}, state, {
          vatMessage: null
          , vatMessageSeverity:null
          , doc: Object.assign({}, state.doc, {
            vat_charged: payload.values.vat_charged
            , total_goods: payload.values.total_goods
            , total: payload.values.total
          })
          })
        
      case types.SAVE_QUOTE_LINE_STARTED:
        return Object.assign({}, state, {
              lineMessage:"Saving..."
              , lineMessageSeverity:FEEDBACK_WAITING
          });
        
      case types.SAVE_QUOTE_LINE_FAILED:
        return Object.assign({}, state, {
            lineMessage:payload.message
            , lineMessageSeverity:payload.severity
          });
        
      case types.SAVE_QUOTE_LINE_COMPLETE:
        return Object.assign({}, state, {
            lineMessage:"Saved ok"
            , lineMessageSeverity:FEEDBACK_MESSAGE
            , line:null
            , lineSavedOK: true
            , docMessage:"Line saved ok"
            , docMessageSeverity:FEEDBACK_MESSAGE
            });
        
      case types.PRINT_QUOTE_STARTED:
        return Object.assign({}, state, {
          docMessage:"Printing Quote " + state.doc.quote_number + "..."
          , docMessageSeverity:FEEDBACK_WAITING
        });
        
      case types.PRINT_QUOTE_FAILED:
        return Object.assign({}, state, {
          docMessage:payload.message
          , docMessageSeverity:payload.severity
        });
        
      case types.PRINT_QUOTE_COMPLETE:
        return Object.assign({}, state, {
          docMessage:"Printed and downloaded ok."
          , docMessageSeverity:FEEDBACK_MESSAGE
        });
        
      case types.LOADING_QUOTE_CUSTOMER_STARTED:
        return Object.assign({}, state, {
          loadingCustomerMessage:"Laoding customer..."
          , loadingCustomerMessageSeverity:FEEDBACK_WAITING
        });
        
      case types.LOADING_QUOTE_CUSTOMER_FAILED:
        return Object.assign({}, state, {
          loadingCustomerMessage:payload.message
          , loadingCustomerMessageSeverity:payload.severity
        });
        
      case types.LOADING_QUOTE_CUSTOMER_COMPLETE:
        return Object.assign({}, state, {
          loadingCustomerMessage:payload.warning
          , loadingCustomerMessageSeverity:payload.warning ? payload.severity : null
        });
        
      case types.DELETE_QUOTE_LINE_STARTED:
        return Object.assign({}, state, {
              lineMessage:"Deleting..."
              , lineMessageSeverity:FEEDBACK_WAITING
          });
        
      case types.DELETE_QUOTE_LINE_FAILED:
        return Object.assign({}, state, {
            lineMessage:payload.message
            , lineMessageSeverity:payload.severity
          });
        
      case types.DELETE_QUOTE_LINE_COMPLETE:
        return Object.assign({}, state, {
            lineMessage:null
            , lineMessageSeverity:null
            , line:null
            , lines:null
            , docMessage: "Line deleted"
            , docMessageSeverity: FEEDBACK_MESSAGE
          });
        
      case types.DELETE_QUOTE_STARTED:
        return Object.assign({}, state, {
              docMessage:"Deleting..."
              , docMessageSeverity:FEEDBACK_WAITING
          });
        
      case types.DELETE_QUOTE_FAILED:
        return Object.assign({}, state, {
            docMessage:payload.message
            , docMessageSeverity:payload.severity
          });
        
      case types.DELETE_QUOTE_COMPLETE:
        return Object.assign({}, state, {
            docMessage:null
            , docMessageSeverity:null
            , doc:null
            , message:"Quote and lines deleted"
            , messageSeverity:FEEDBACK_MESSAGE
          });

      case types.GET_NO_QUOTES_STARTED:
        return Object.assign({}, state, {
          noQuotesMessage:"Loading...",
          noQuotesSeverity:FEEDBACK_WAITING,
          noQuotes:null          
        });
      
      case types.GET_NO_QUOTES_COMPLETE:
          return Object.assign({}, state, {
          noQuotesMessage:null,
          noQuotesSeverity:null,
          noQuotes:payload          
        });

      case types.GET_NO_QUOTES_FAILED:
          return Object.assign({}, state, {
          noQuotesMessage:payload.message,
          noQuotesSeverity:payload.severity,          
          noQuotes:null          
        });

      case types.GET_NO_QUOTE_REASONS_STARTED:
        return Object.assign({}, state, {
          noQuotesMessage:"Loading...",
          noQuotesSeverity:FEEDBACK_WAITING,
          reasons:null          
        });
      
      case types.GET_NO_QUOTE_REASONS_COMPLETE:
          return Object.assign({}, state, {
          noQuotesMessage:null,
          noQuotesSeverity:null,
          reasons:payload          
        });

      case types.GET_NO_QUOTE_REASONS_FAILED:
          return Object.assign({}, state, {
          noQuotesMessage:payload.message,
          noQuotesSeverity:payload.severity,          
          reasons:null          
        });
  
      case types.SAVE_NO_QUOTE_STARTED:
        return Object.assign({}, state, {
          noQuotesMessage:"Loading...",
          noQuotesSeverity:FEEDBACK_WAITING          
        });
      
      case types.SAVE_NO_QUOTE_COMPLETE:
        return Object.assign({}, state, {
          noQuotesMessage:null,
          noQuotesSeverity:null          
        });

      case types.SAVE_NO_QUOTE_FAILED:
        return Object.assign({}, state, {
          noQuotesMessage:payload.message,
          noQuotesSeverity:payload.severity          
        });

      case types.GET_NO_QUOTE_STARTED:
        return Object.assign({}, state, {
          noQuotesMessage:"Loading...",
          noQuotesSeverity:FEEDBACK_WAITING,
          noQuote:null          
        });
      
      case types.GET_NO_QUOTE_COMPLETE:
        return Object.assign({}, state, {
          noQuotesMessage:null,
          noQuotesSeverity:null,
          noQuote:payload                    
        });

      case types.GET_NO_QUOTE_FAILED:
        return Object.assign({}, state, {
          noQuotesMessage:payload.message,
          noQuotesSeverity:payload.severity,
          noQuote:null          
        });
          
      case types.DELETE_NO_QUOTE_STARTED:
        return Object.assign({}, state, {
          noQuotesMessage:"Deleting...",
          noQuotesSeverity:FEEDBACK_WAITING,
          noQuote:null          
        });
      
      case types.DELETE_NO_QUOTE_COMPLETE:
        return Object.assign({}, state, {
          noQuotesMessage:null,
          noQuotesSeverity:null,
          noQuote:payload                    
        });

      case types.DELETE_NO_QUOTE_FAILED:
        return Object.assign({}, state, {
          noQuotesMessage:payload.message,
          noQuotesSeverity:payload.severity,
          noQuote:null          
        });

      case types.CLEAR_ALL_QUOTE_MESSAGES:
          return Object.assign({}, state, {
            message:null,
            mesageSeverity:null,
            searchMessage:null,
            searchMesageSeverity:null,
            docMessage:null,
            docMessageSeverity:null,
            noQuotesMessage:null,
            noQuotesSeverity:null
            });

      case types.CLEAR_NO_QUOTE_MESSAGES:
        return Object.assign({}, state, {
          noQuotesMessage:null,
          noQuotesSeverity:null
        });

      default:
        return state;
  }
}
