import * as types from '../actions/types'
import { FEEDBACK_WAITING } from '../lib/message-types'

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, {type, payload}) => {
  switch (type) {
    case types.LOAD_STOCKENQUIRY_PART_STARTED:
      return Object.assign({}, state, {
        partMessage: "Loading part..."
        , part:null
        , partMessageSeverity:FEEDBACK_WAITING
        , partComments: null
        , lines: null
        , altLines: null
        , osprLines: null
        , osexLines: null
        , quoteLines: null
        , linesMessage: null
        , linesMessageSeverity:null
        , osexLinesMessage: null
        , osexLinesMessageSeverity:null
        , osprLinesMessage: null
        , osprLinesMessageSeverity:null
        , quoteLinesMessage: null
        , quoteLinesMessageSeverity:null
        , linesRequested: null
        , altLinesRequested: null
        , osexLinesRequested: null
        , osprLinesRequested: null
        , quoteLinesRequested: null
        , partCommentsRequested: null
      });
      
    case types.LOAD_STOCKENQUIRY_PART_FAILED:
      return Object.assign({}, state, {
        partMessage: payload.message
        , partMessageSeverity:payload.severity
      });
      
    case types.LOAD_STOCKENQUIRY_PART_COMPLETE:
      return Object.assign({}, state, {
        partMessage: null
        , partMessageSeverity:null
      });
      
    case types.LOAD_STOCKENQUIRY_PART_COMMENTS_STARTED:
      return Object.assign({}, state, {
        partCommentsMessage: "Loading comments..."
        , partCommentsMessageSeverity:FEEDBACK_WAITING
        , partCommentsRequested:true
      });
      
    case types.LOAD_STOCKENQUIRY_PART_COMMENTS_FAILED:
      return Object.assign({}, state, {
        partCommentsMessage: payload.message
        , partCommentsMessageSeverity:payload.severity
      });
      
    case types.LOAD_STOCKENQUIRY_PART_COMMENTS_COMPLETE:
      return Object.assign({}, state, {
        partCommentsMessage: null
        , partCommentsMessageSeverity:null
        , partComments: payload.comments
      });
      
    case types.SET_STOCKENQUIRY_PART:
      return Object.assign({}, state, {
        part: payload.part
        , partComments: null
        , lines: null
        , altLines: null
        , osprLines: null
        , osexLines: null
        , quoteLines: null
        , linesMessage: null
        , linesMessageSeverity:null
        , osexLinesMessage: null
        , osexLinesMessageSeverity:null
        , osprLinesMessage: null
        , osprLinesMessageSeverity:null
        , quoteLinesMessage: null
        , quoteLinesMessageSeverity:null
        , linesRequested: null
        , altLinesRequested: null
        , osexLinesRequested: null
        , osprLinesRequested: null
        , quoteLinesRequested: null
        , partCommentsRequested: null
      });
      
    // case types.LOAD_STOCKENQUIRY_STARTED:
    //   return Object.assign({}, state, {
    //     message: "Loading stock..."
    //     , messageSeverity:FEEDBACK_WAITING
    //   });
    //   
    // case types.LOAD_STOCKENQUIRY_FAILED:
    //   return Object.assign({}, state, {
    //     message: payload.message
    //     , messageSeverity:payload.severity
    //   });
    //   
    // case types.LOAD_STOCKENQUIRY_ERRORED:
    //   return Object.assign({}, state, {
    //     message: payload.error.message
    //     , messageSeverity:payload.severity
    //   });
    //   
    // case types.LOAD_STOCKENQUIRY_COMPLETE:
    //   return Object.assign({}, state, {
    //     message: null
    //     , messageSeverity:null
    //     , summary: payload.summary
    //   });
    //   
    case types.LOAD_STOCKENQUIRY_LINES_STARTED:
      return Object.assign({}, state, {
        linesMessage: "Loading lines..."
        , linesMessageSeverity:FEEDBACK_WAITING
        , linesRequested: true
      });
      
    case types.LOAD_STOCKENQUIRY_LINES_FAILED:
      return Object.assign({}, state, {
        linesMessage: payload.message
        , linesMessageSeverity:payload.severity
      });
      
    case types.LOAD_STOCKENQUIRY_LINES_COMPLETE:
      return Object.assign({}, state, {
        linesMessage: null
        , linesMessageSeverity:null
        , lines: payload.lines
      });
      
    case types.LOAD_STOCKENQUIRY_ALTS_STARTED:
      return Object.assign({}, state, {
        altLinesMessage: "Loading alternates..."
        , altLinesMessageSeverity:FEEDBACK_WAITING
        , altLinesRequested: true
      });
      
    case types.LOAD_STOCKENQUIRY_ALTS_FAILED:
      return Object.assign({}, state, {
        altLinesMessage: payload.message
        , altLinesMessageSeverity:payload.severity
      });
      
    case types.LOAD_STOCKENQUIRY_ALTS_COMPLETE:
      return Object.assign({}, state, {
        altLinesMessage: null
        , altLinesMessageSeverity:null
        , altLines: payload.lines
        , altSummary: payload.summary
      });
      
    case types.LOAD_STOCKENQUIRY_OSPR_STARTED:
      return Object.assign({}, state, {
        osprLinesMessage: "Loading OSPRs..."
        , osprLinesMessageSeverity:FEEDBACK_WAITING
        , osprLinesRequested: true
      });
      
    case types.LOAD_STOCKENQUIRY_OSPR_FAILED:
      return Object.assign({}, state, {
        osprLinesMessage: payload.message
        , osprLinesMessageSeverity:payload.severity
      });
      
    case types.LOAD_STOCKENQUIRY_OSPR_COMPLETE:
      return Object.assign({}, state, {
        osprLinesMessage: null
        , osprLinesMessageSeverity:null
        , osprLines: payload.lines
      });
      
    case types.LOAD_STOCKENQUIRY_OSEX_STARTED:
      return Object.assign({}, state, {
        osexLinesMessage: "Loading exchanges..."
        , osexLinesMessageSeverity:FEEDBACK_WAITING
        , osexLinesRequested: true
      });
      
    case types.LOAD_STOCKENQUIRY_OSEX_FAILED:
      return Object.assign({}, state, {
        osexLinesMessage: payload.message
        , osexLinesMessageSeverity:payload.severity
      });
      
    case types.LOAD_STOCKENQUIRY_OSEX_COMPLETE:
      return Object.assign({}, state, {
        osexLinesMessage: null
        , osexLinesMessageSeverity:null
        , osexLines: payload.lines
      });
      
    case types.LOAD_STOCKENQUIRY_QUOTES_STARTED:
      return Object.assign({}, state, {
        quotesLinesMessage: "Loading quotes..."
        , quotesLinesMessageSeverity:FEEDBACK_WAITING
        , quoteLinesRequested: true
      });
      
    case types.LOAD_STOCKENQUIRY_QUOTES_FAILED:
      return Object.assign({}, state, {
        quotesLinesMessage: payload.message
        , quotesLinesMessageSeverity:payload.severity
      });
      
    case types.LOAD_STOCKENQUIRY_QUOTES_COMPLETE:
      return Object.assign({}, state, {
        quotesLinesMessage: null
        , quotesLinesMessageSeverity:null
        , quotesLines: payload.lines
      });
      
    case types.CLEAR_STOCKENQUIRY:
      return INITIAL_STATE;
      
    default:
      return state;
  }
}
