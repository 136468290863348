import packageJson from '../package.json'

export const api = {
  url: process.env.APIORIGIN
  // note devurl 'http://localhost:3001/'
}

export const TYPEWATCH_DELAY = 3000

export const token = {
  name: process.env.TOKENHEADER,
  header: process.env.TOKENHEADER,
  renew: 1800000 /// 30 mins
}

export const GUI_VERSION = packageJson.version
export const GUI_NAME = "2023 fixes 7 Fix broken DA line load"
export const GUI_QUOTE = ''

export const GUI_TYPE = process.env.GUI_TYPE || "Not set"

export const SERVERS = process.env.SERVERS.split(',')
