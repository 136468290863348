import React,{Component} from 'react'
import { FeedbackMessage } from './FeedbackMessage'
import { FEEDBACK_ERROR } from '../lib/message-types'

class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { error: false };
    }
  
   componentDidCatch(error, info) {
        //   // You can also log the error to an error reporting service
        //   logErrorToMyService(error, info);
        this.setState({error,info})
   }

   onClick = () => {
       this.setState({error:null, info:null})
   }

    render() {
      if (this.state.error) {
            return <FeedbackMessage
                message={this.state.error.message}
                type={FEEDBACK_ERROR}
                onClick = {this.onClick}
            />      
        }
  
      return this.props.children;
    }
  }

export default ErrorBoundary