export const GRNS_SEARCH_STARTED = 'GRNS_SEARCH_STARTED'
export const GRNS_SEARCH_FAILED = 'GRNS_SEARCH_FAILED'
export const GRNS_SEARCH_COMPLETE = 'GRNS_SEARCH_COMPLETE'

export const CREATE_GRN_FORM_SUBMITTED = 'CREATE_GRN_FORM_SUBMITTED'
export const CREATE_GRN_FAILED = 'CREATE_GRN_FAILED'
export const CREATE_GRN_COMPLETE = 'CREATE_GRN_COMPLETE'

export const EDIT_GRN_FORM_SUBMIT_STARTED = 'EDIT_GRN_FORM_SUBMIT_STARTED'
export const UPDATE_GRN_STARTED = 'UPDATE_GRN_STARTED'
export const UPDATE_GRN_FAILED = 'UPDATE_GRN_FAILED'
export const UPDATE_GRN_COMPLETE = 'UPDATE_GRN_COMPLETE'
export const SET_GRN_VALUES = 'SET_GRN_VALUES'

export const SAVE_GRN_LINE_STARTED = 'SAVE_GRN_LINE_STARTED'
export const SAVE_GRN_LINE_FAILED = 'SAVE_GRN_LINE_FAILED'
export const SAVE_GRN_LINE_COMPLETE = 'SAVE_GRN_LINE_COMPLETE'

export const GET_GRN_STARTED = 'GET_GRN_STARTED'
export const GET_GRN_FAILED = 'GET_GRN_FAILED'
export const GET_GRN_COMPLETE = 'GET_GRN_COMPLETE'

export const LOCK_GRN_STARTED = 'LOCK_GRN_STARTED'
export const LOCK_GRN_FAILED = 'LOCK_GRN_FAILED'
export const LOCK_GRN_COMPLETE = 'LOCK_GRN_COMPLETE'

export const UNLOCK_GRN_STARTED = 'UNLOCK_GRN_STARTED'
export const UNLOCK_GRN_FAILED = 'UNLOCK_GRN_FAILED'
export const UNLOCK_GRN_COMPLETE = 'UNLOCK_GRN_COMPLETE'

export const LOADING_GRN_LINES_STARTED = 'LOADING_GRN_LINES_STARTED'
export const LOADING_GRN_LINES_FAILED = 'LOADING_GRN_LINES_FAILED'
export const LOADING_GRN_LINES_COMPLETE = 'LOADING_GRN_LINES_COMPLETE'

export const LOADING_GRN_LINE_STARTED = 'LOADING_GRN_LINE_STARTED'
export const LOADING_GRN_LINE_FAILED = 'LOADING_GRN_LINE_FAILED'
export const LOADING_GRN_LINE_COMPLETE = 'LOADING_GRN_LINE_COMPLETE'

export const GRN_SOURCE_GRN_LINE_UNLINKED = 'GRN_SOURCE_GRN_LINE_UNLINKED'
export const GRN_SOURCE_GRN_LINE_LINKED = 'GRN_SOURCE_GRN_LINE_LINKED'

export const CLEAR_GRN_LINE = 'CLEAR_GRN_LINE'
export const NEW_GRN_LINE_STARTED = 'NEW_GRN_LINE_STARTED'
export const GRN_LINE_SET_PART = 'GRN_LINE_SET_PART'

export const PRINT_GRN_STARTED = 'PRINT_GRN_STARTED'
export const PRINT_GRN_FAILED = 'PRINT_GRN_FAILED'
export const PRINT_GRN_COMPLETE = 'PRINT_GRN_COMPLETE'

export const LOADING_GRN_CUSTOMER_STARTED = 'LOADING_GRN_CUSTOMER_STARTED'
export const LOADING_GRN_CUSTOMER_FAILED = 'LOADING_GRN_CUSTOMER_FAILED'
export const LOADING_GRN_CUSTOMER_COMPLETE = 'LOADING_GRN_CUSTOMER_COMPLETE'

export const CLEAR_ALL_GRN_MESSAGES = 'CLEAR_ALL_GRN_MESSAGES'

export const DELETE_GRN_STARTED = 'DELETE_GRN_STARTED'
export const DELETE_GRN_FAILED = 'DELETE_GRN_FAILED'
export const DELETE_GRN_COMPLETE = 'DELETE_GRN_COMPLETE'

export const DELETE_GRN_LINE_STARTED = 'DELETE_GRN_LINE_STARTED'
export const DELETE_GRN_LINE_FAILED = 'DELETE_GRN_LINE_FAILED'
export const DELETE_GRN_LINE_COMPLETE = 'DELETE_GRN_LINE_COMPLETE'

export const NEW = 'new'
export const START_NEW_GRN = 'START_NEW_GRN'