import * as types from '../actions/types'

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, {type, payload}) => {
  switch (type) {
    case types.ADD_STOCK_LINE_ADD:
      return Object.assign({}, state, payload);
    case types.ADD_STOCK_LINE_CLEAR:
      return INITIAL_STATE
    default:
      return state;
  }
}
